import { FC, useState } from 'react';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';

import { deleteBlog, getBlog } from '../../actions/blog.action';
import { clearBlog } from '../../slices/blog.slice';
import { useAppDispatch } from '../../store/store';
import { IBlog } from '../../store/types';

import { BlogItemComponent } from './blog-item.component';

export const BlogListComponent: FC<IProps> = ({ list }) => {
  const dispatch = useAppDispatch();

  const navigation = useNavigate();

  const [visibleModal, setVisibleModal] = useState<{ isVisible: boolean; id: number }>({
    id: 0,
    isVisible: false,
  });
  const handleRedirect = async (id: number) => {
    dispatch(clearBlog());
    await dispatch(getBlog({ id }));

    navigation('/blog/create-redact-blog', { state: { id } });
  };

  const openWarningModal = (id: number) => {
    setVisibleModal({ id, isVisible: true });
  };

  const handleDelete = async () => {
    await dispatch(deleteBlog({ id: visibleModal.id }));
    setVisibleModal({ id: 0, isVisible: false });
  };

  return (
    <>
      <div className={'grid grid-cols-4 gap-4'}>
        {list.length
          ? list.map((item, key) => (
              <BlogItemComponent
                content={item}
                onClick={handleRedirect}
                openWarningModal={openWarningModal}
                key={key}
              />
            ))
          : null}
      </div>
      <Dialog
        header="Вы действительно хотите удалить блог?"
        visible={visibleModal.isVisible}
        style={{ width: '50vw' }}
        onHide={() => setVisibleModal({ ...visibleModal, isVisible: false })}
      >
        <Button label="Удалить" severity="success" onClick={handleDelete} className={'w-full'} />
      </Dialog>
    </>
  );
};

interface IProps {
  list: IBlog[];
}
