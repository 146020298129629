import { FC, useState } from 'react';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';

import { deleteCar, getCar } from '../../actions/car.action';
import { clearCars } from '../../slices/car.slice';
import { useAppDispatch } from '../../store/store';
import { ICar } from '../../store/types';

import { CarItemComponent } from './car-item.component';

export const CarsListComponent: FC<IProps> = ({ list }) => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const [visibleModal, setVisibleModal] = useState<{ isVisible: boolean; id: number }>({
    id: 0,
    isVisible: false,
  });
  const handleRedirect = async (id: number) => {
    dispatch(clearCars());
    await dispatch(getCar({ id }));
    navigation('/car/create-redact-car', { state: { id } });
  };

  const openWarningModal = (id: number) => {
    setVisibleModal({ id, isVisible: true });
  };

  const handleDelete = async () => {
    await dispatch(deleteCar({ id: visibleModal.id }));
    setVisibleModal({ id: 0, isVisible: false });
  };

  return (
    <>
      <div className={'grid grid-cols-4 gap-4'}>
        {list.length
          ? list.map((item, key) => (
              <CarItemComponent
                content={item}
                onClick={handleRedirect}
                openWarningModal={openWarningModal}
                key={key}
              />
            ))
          : null}
      </div>
      <Dialog
        header="Вы действительно хотите удалить машину?"
        visible={visibleModal.isVisible}
        style={{ width: '50vw' }}
        onHide={() => setVisibleModal({ ...visibleModal, isVisible: false })}
      >
        <Button label="Удалить" severity="success" onClick={handleDelete} className={'w-full'} />
      </Dialog>
    </>
  );
};

interface IProps {
  list: ICar[];
}
