import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAllPriceList, getPriceContent } from '../actions/price-list.action';
import { IContent, IPriceItem, IStore } from '../store/types';

const initialState = {
  content: {
    description: {
      description: '',
      title: '',
    },
    heading: {
      description: '',
      title: '',
    },
  },
  list: [],
} as IStore['priceList'];

export const priceListSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      getAllPriceList.fulfilled,
      (state, action: PayloadAction<{ data: IPriceItem[] }>) => {
        state.list = action.payload.data.sort((a, b) => a.name.localeCompare(b.name));
      },
    );
    builder.addCase(
      getPriceContent.fulfilled,
      (state, action: PayloadAction<{ heading: IContent; description: IContent }>) => {
        state.content = action.payload;
      },
    );
  },
  initialState,
  name: 'priceList',
  reducers: {
    updatePrice: (state, action: PayloadAction<IPriceItem>) => {
      state.list = state.list.map((item) =>
        item.id === action.payload.id ? action.payload : item,
      );
    },
    updatePriceDescription: (state, action: PayloadAction<IContent>) => {
      state.content.description = action.payload;
    },
    updatePriceHeading: (state, action: PayloadAction<IContent>) => {
      state.content.heading = action.payload;
    },
  },
});

export const { updatePrice, updatePriceDescription, updatePriceHeading } = priceListSlice.actions;
