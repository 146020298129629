import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAllHomeContent, getAllReviews } from '../actions/main.action';
import { IDefaultStackSection, IImageItem, IReviewItem, ISections, IStore } from '../store/types';

const initialState = {
  reviews: {
    list: [],
  },
  sections: {
    about: {
      description: '',
      name: '',
      subtitle: '',
      title: '',
    },
    banner: {
      description: '',
      gallery: [],
      name: '',
      subtitle: '',
      title: '',
    },
    benefits: {
      description: '',
      list_item_one: {
        description: '',
        heading: '',
      },
      list_item_three: {
        description: '',
        heading: '',
      },
      list_item_two: {
        description: '',
        heading: '',
      },
      name: '',
      subtitle: '',
      title: '',
    },
    cars: {
      description: '',
      name: '',
      subtitle: '',
      title: '',
    },
    testimonials: {
      description: '',
      name: '',
      subtitle: '',
      title: '',
    },
    videos: {
      description: '',
      name: '',
      subtitle: '',
      title: '',
    },
  },
} as IStore['main'];

export const mainSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getAllReviews.fulfilled, (state, action: PayloadAction<IReviewItem[] | []>) => {
      state.reviews.list = action.payload;
    });
    builder.addCase(getAllHomeContent.fulfilled, (state, action: PayloadAction<ISections>) => {
      state.sections = action.payload;
    });
  },
  initialState,
  name: 'main',
  reducers: {
    deleteBannerImageFromGallery: (state, action: PayloadAction<IImageItem[]>) => {
      if (!state.sections.banner.gallery) return;
      state.sections.banner.gallery = action.payload;
    },
    updateAboutUs: (state, action: PayloadAction<IDefaultStackSection>) => {
      state.sections.about = action.payload;
    },
    updateBanner: (state, action: PayloadAction<IDefaultStackSection>) => {
      state.sections.banner = action.payload;
    },
    updateCars: (state, action: PayloadAction<IDefaultStackSection>) => {
      state.sections.cars = action.payload;
    },
    updateGalleryBanner: (state, action: PayloadAction<IImageItem[]>) => {
      state.sections.banner.gallery = state.sections.banner.gallery.length
        ? [...state.sections.banner.gallery, ...action.payload]
        : action.payload;
    },
    updateOurBenefits: (state, action: PayloadAction<IDefaultStackSection>) => {
      state.sections.benefits = action.payload;
    },
    updateTestimonials: (state, action: PayloadAction<IDefaultStackSection>) => {
      state.sections.testimonials = action.payload;
    },
    updateVideoBlog: (state, action: PayloadAction<IDefaultStackSection>) => {
      state.sections.videos = action.payload;
    },
  },
});

export const {
  updateBanner,
  updateTestimonials,
  updateVideoBlog,
  updateAboutUs,
  updateOurBenefits,
  updateCars,
  updateGalleryBanner,
  deleteBannerImageFromGallery,
} = mainSlice.actions;
