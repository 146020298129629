import React, { useState } from 'react';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { addTag, deleteTag } from '../../actions/blog.action';
import { TagItemComponent } from '../../components/blog/tag-item.component';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { useAppDispatch, useAppSelector } from '../../store/store';

export const TagsListPage = () => {
  const dispatch = useAppDispatch();
  const { list } = useAppSelector(({ blog }) => blog.tags);

  const [tagValue, setTagValue] = useState('');

  const handleRemove = (id: number) => {
    dispatch(deleteTag({ id }));
  };

  const handleSaveTag = () => {
    dispatch(addTag({ name: tagValue }));
    setTagValue('');
  };

  return (
    <ContainerTemplate>
      <div className={'flex items-end mb-10'}>
        <div className="flex flex-1 flex-column gap-2 mr-10">
          <label htmlFor="tag">Добавить новый тег</label>
          <InputText
            id="tag"
            aria-describedby="tag-help"
            value={tagValue ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTagValue(e.target.value)}
          />
        </div>
        <Button label="Сохранить" icon="pi pi-check" iconPos="right" onClick={handleSaveTag} />
      </div>

      <div className={'flex flex-wrap'}>
        {list.map((item, key) => (
          <TagItemComponent item={item} onRemove={handleRemove} key={key} />
        ))}
      </div>
    </ContainerTemplate>
  );
};
