import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputTextarea } from 'primereact/inputtextarea';

import { updateCarPartsAction } from '../../actions/car-parts.action';
import { updateFormContentAction } from '../../actions/form.action';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { getNameForWhatCheckInCars } from "../../helpers";
import { updateCarPartDescription, updateCarPartTitle } from '../../slices/car-parts.slice';
import { updateWhatsCheckedPage } from '../../slices/form.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { ICarParts } from '../../store/types';

export const CarPartsPage = () => {
  const dispatch = useAppDispatch();

  const { list } = useAppSelector((store) => store.carParts.carParts);
  const { whatsChecked } = useAppSelector((store) => store.formPage);

  const handleUpdateContentTitle = (value: string) => {
    dispatch(updateWhatsCheckedPage({ ...whatsChecked, title: value }));
  };

  const handleUpdateContentDescription = (value: string) => {
    dispatch(updateWhatsCheckedPage({ ...whatsChecked, description: value }));
  };

  const handleSaveSection = () => {
    dispatch(updateFormContentAction({ section: whatsChecked }));
  };

  const handleChangeTitle = async (value: string, id: number) => {
    dispatch(updateCarPartTitle({ id, value }));
  };

  const handleChangeDescription = async (value: string, id: number) => {
    dispatch(updateCarPartDescription({ id, value }));
  };

  const handleSave = async (data: ICarParts) => {
    if (!data.title.length || !data.description.length) return;
    await dispatch(updateCarPartsAction(data));
  };

  return (
    <ContainerTemplate>
      <Card
        title={`Что проверяем в машине. Заголовок и описание`}
        className={'border-2 border-yellow mb-10'}
      >
        <div>
          <label htmlFor="headline">Заголовок</label>
          <InputTextarea
            className={'w-full'}
            id="headline"
            value={whatsChecked.title ?? ''}
            onChange={({ target }) => handleUpdateContentTitle(target.value)}
          />
        </div>
        <div>
          <label htmlFor="description">Описание</label>
          <InputTextarea
            className={'w-full'}
            id="description"
            value={whatsChecked.description ?? ''}
            onChange={({ target }) => handleUpdateContentDescription(target.value)}
          />
        </div>
        <div className={'flex justify-end'}>
          <Button
            label="Сохранить"
            icon="pi pi-check"
            className={'my-2'}
            onClick={handleSaveSection}
          />
        </div>
      </Card>
      {list.map((item, key) => (
        <Card
          title={`${getNameForWhatCheckInCars(item.name)}`}
          className={'border-2 border-yellow mb-10'}
          key={key}
        >
          <InputTextarea
            className={'w-full'}
            id="headline"
            value={item.title ?? ''}
            onChange={({ target }) => handleChangeTitle(target.value, item.id)}
          />
          <InputTextarea
            className={'w-full'}
            id="description"
            value={item.description ?? ''}
            onChange={({ target }) => handleChangeDescription(target.value, item.id)}
          />
          <div className={'flex justify-end'}>
            <Button
              label="Сохранить"
              icon="pi pi-check"
              className={'my-2'}
              onClick={() => handleSave(item)}
            />
          </div>
        </Card>
      ))}
    </ContainerTemplate>
  );
};
