import { FC } from 'react';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { updatePriceList } from '../../actions/price-list.action';
import { getNameByPriceItem } from '../../helpers';
import { updatePrice } from '../../slices/price-list.slice';
import { useAppDispatch } from '../../store/store';
import { INamePriceItem, IPriceItem } from '../../store/types';

export const PriceItemComponent: FC<IProps> = ({ item }) => {
  const dispatch = useAppDispatch();

  const nameCard = getNameByPriceItem(item.name as INamePriceItem);

  const handleUpdatePriceTitle = (v: string) => {
    dispatch(updatePrice({ ...item, title: v }));
  };
  const handleUpdatePriceSampleCars = (v: string) => {
    dispatch(updatePrice({ ...item, sample_cars: v }));
  };
  const handleUpdatePriceAutofitPrice = (v: string) => {
    const num = parseInt(v);
    if (isNaN(num)) return;
    dispatch(updatePrice({ ...item, autofit_price_pln: num }));
  };
  const handleUpdatePriceInspectionPrice = (v: string) => {
    const num = parseInt(v);
    if (isNaN(num)) return;
    dispatch(updatePrice({ ...item, inspection_price_pln: num }));
  };

  const handleSave = async () => {
    await dispatch(updatePriceList({ data: item }));
  };

  return (
    <div className={'w-full p-4 border border-yellow rounded-md'}>
      <h5 className={'text-center mb-4 text-yellow text-base'}>{nameCard}</h5>
      <div className={'mb-2'}>
        <label htmlFor="headline">Класс авто</label>
        <InputText
          className={'w-full'}
          id="headline"
          value={item.title ?? ''}
          onChange={({ target }) => handleUpdatePriceTitle(target.value)}
        />
      </div>
      <div className={'mb-2'}>
        <label htmlFor="headline">Пример авто</label>
        <InputText
          className={'w-full'}
          id="headline"
          value={item.sample_cars ?? ''}
          onChange={({ target }) => handleUpdatePriceSampleCars(target.value)}
        />
      </div>
      <div className={'mb-2'}>
        <label htmlFor="headline">Цена подбора авто</label>
        <InputText
          className={'w-full'}
          id="headline"
          type={'number'}
          value={item.autofit_price_pln.toString() ?? ''}
          onChange={({ target }) => handleUpdatePriceAutofitPrice(target.value)}
        />
      </div>
      <div className={'mb-4'}>
        <label htmlFor="headline">Цена разового осмотра</label>
        <InputText
          className={'w-full'}
          id="headline"
          type={'number'}
          value={item.inspection_price_pln.toString() ?? ''}
          onChange={({ target }) => handleUpdatePriceInspectionPrice(target.value)}
        />
      </div>
      <div className={'w-full'}>
        <Button label="Сохранить" icon="pi pi-check" className={'w-full'} onClick={handleSave} />
      </div>
    </div>
  );
};

interface IProps {
  item: IPriceItem;
}
