import { FC } from 'react';

import { Button } from 'primereact/button';

import { IReviewItem } from '../../store/types';

export const ReviewsItemsComponent: FC<IProps> = ({ items, onDelete }) => {
  return (
    <div className={'grid grid-cols-4 gap-4'}>
      {items.map((item, key) => (
        <div className={`w-[306px] flex flex-col items-center justify-center`} key={key}>
          <img
            src={item.url}
            alt={'review'}
            className="w-full rounded-2xl h-full min-h-[325px] max-h-[325px] object-cover"
          />
          <Button
            icon="pi pi-times"
            iconPos="right"
            label="Удалить"
            severity="danger"
            className={'w-9/12 mt-2'}
            onClick={() => onDelete(item.id)}
          />
        </div>
      ))}
    </div>
  );
};

interface IProps {
  items: IReviewItem[];
  onDelete: (id: number) => void;
}
