import React from 'react';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Editor, EditorTextChangeEvent } from 'primereact/editor';

import { updatePageAction } from '../../actions/pages.action';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { updatePageHeader } from '../../slices/pages.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';

export const PagesSeo = () => {
  const dispatch = useAppDispatch();

  const { data } = useAppSelector((state) => state.pages);
  if (!data.length) return null;

  const handleSavePage = (id: number) => {
    const found = data.find((item) => item.id === id);
    if (!found) return;
    dispatch(updatePageAction({ header: found.header, id: found.id, title: found.title }));
  };

  const handleUpdatePageHeader = (v: string, id: number) => {
    dispatch(updatePageHeader({ id, value: v }));
  };

  return (
    <ContainerTemplate>
      {data.map((item, key) => (
        <Card
          key={key}
          title={`SEO Страницы ${item.slug}`}
          className={'border-2 border-yellow mb-10'}
        >
          <div className={'flex flex-col w-full'}>
            <Editor
              id={'car_ceo'}
              value={item.header ?? ''}
              onTextChange={(e: EditorTextChangeEvent) =>
                handleUpdatePageHeader(e.htmlValue ? e.htmlValue : '', item.id)
              }
              style={{ height: '320px' }}
              headerTemplate={<></>}
            />
          </div>
          <div className={'flex justify-end'}>
            <Button
              label="Сохранить"
              icon="pi pi-check"
              className={'mt-2'}
              onClick={() => handleSavePage(item.id)}
            />
          </div>
        </Card>
      ))}
    </ContainerTemplate>
  );
};
