import { createAsyncThunk } from '@reduxjs/toolkit';

import { api, ISettingData } from '../api/api';
import { AppDispatch } from '../store/store';
import { ISettings } from '../store/types';

import { handlePayloadInfoAction } from './globall.action';

export const updateSettingsAction = createAsyncThunk<void, ISettingData, { dispatch: AppDispatch }>(
  'settings/update',
  async (data, { dispatch }) => {
    const { status } = await api.settings.updateSettings(data);

    dispatch(handlePayloadInfoAction({ statusCode: status }));

    await dispatch(getAllSettings());
  },
);

export const getAllSettings = createAsyncThunk<ISettings, void>('settings/get', async () => {
  const { data } = await api.settings.getAllSettings();
  if (!data) throw 'error';
  return data;
});
