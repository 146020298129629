import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputTextarea } from 'primereact/inputtextarea';

import { updatePriceContentSection } from '../../actions/price-list.action';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { PriceItemComponent } from '../../components/price-list/price-item.component';
import { updatePriceDescription, updatePriceHeading } from '../../slices/price-list.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';

export const PriceListPage = () => {
  const dispatch = useAppDispatch();
  const { list, content } = useAppSelector((state) => state.priceList);
  const { heading, description } = content;

  const handleUpdateHeadingTitle = (v: string) => {
    dispatch(updatePriceHeading({ ...heading, title: v }));
  };

  const handleUpdateHeadingDescription = (v: string) => {
    dispatch(updatePriceHeading({ ...heading, description: v }));
  };

  const handleSaveHeading = async () => {
    await dispatch(updatePriceContentSection({ data: heading }));
  };

  const handleUpdateDescription = (v: string) => {
    dispatch(updatePriceDescription({ ...description, description: v }));
  };

  const handleSaveDescription = async () => {
    await dispatch(updatePriceContentSection({ data: description }));
  };

  if (!list.length) return null;

  return (
    <ContainerTemplate>
      <Card title="Заголовок и описание" className={'border-2 border-yellow mb-10'}>
        <div>
          <label htmlFor="headline">Заголовок</label>
          <InputTextarea
            className={'w-full'}
            id="headline"
            value={heading.title ?? ''}
            onChange={({ target }) => handleUpdateHeadingTitle(target.value)}
          />
        </div>
        <div className="mt-2">
          <label htmlFor="description">Описание</label>
          <InputTextarea
            className={'w-full'}
            id="description"
            value={heading.description ?? ''}
            onChange={({ target }) => handleUpdateHeadingDescription(target.value)}
          />
        </div>
        <Button
          label="Сохранить"
          icon="pi pi-check"
          className={'mt-2'}
          onClick={handleSaveHeading}
        />
      </Card>
      <div className={'grid grid-cols-4 gap-4'}>
        {list.map((item, key) => (
          <PriceItemComponent item={item} key={key} />
        ))}
      </div>
      <Card title="Дополнительная информация" className={'border-2 border-yellow mt-10'}>
        <div>
          <label htmlFor="description">Описание</label>
          <InputTextarea
            className={'w-full'}
            id="description"
            value={description.description ?? ''}
            onChange={({ target }) => handleUpdateDescription(target.value)}
          />
        </div>
        <Button
          label="Сохранить"
          icon="pi pi-check"
          className={'mt-2'}
          onClick={handleSaveDescription}
        />
      </Card>
    </ContainerTemplate>
  );
};
