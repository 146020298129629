import slugify from '@sindresorhus/slugify';

import { INamePriceItem, INameWhatCheckInCar } from '../store/types';

export const Slugify = (str: string) => {
  return slugify(str);
};

export const getNameForWhatCheckInCars = (type: INameWhatCheckInCar) => {
  switch (type) {
    case INameWhatCheckInCar.CHECKING_VEHICLE_DOCUMENTS:
      return 'Проверка документов автомобиля';
    case INameWhatCheckInCar.CHECKING_CONTROL:
      return 'Проверка блоков управления';
    case INameWhatCheckInCar.ASSESSMENT_STATE_BODY:
      return 'Оценка состояния кузова, навесных и несущих элементов конструкции.';
    case INameWhatCheckInCar.IGNITION_SYSTEMS:
      return 'Системы зажигания';
    case INameWhatCheckInCar.TRANSMISSIONS_CLUTCHES:
      return 'Трансмиссии и сцепления';
    case INameWhatCheckInCar.READING_ENGINE_OPERATING:
      return 'Считываение параметров работы двигателя';
    case INameWhatCheckInCar.FUEL_SYSTEM:
      return 'Топливная система';
    case INameWhatCheckInCar.BRAKE_SYSTEM:
      return 'Тормозная система';
    case INameWhatCheckInCar.ASSESSMENT_STATE_CHASSIS:
      return 'Оценка сосотояния ходовой части';
    case INameWhatCheckInCar.MANDATORY_TEST_DRIVE:
      return 'Проводится обязательный тест драйв';
    case INameWhatCheckInCar.AGGREGATE_OPERATION_CORRECTIONS:
      return 'Коррекции работы агрегатов';
    default:
      return '';
  }
}

export const getNameByPriceItem = (type: INamePriceItem) => {
  switch (type) {
    case INamePriceItem.COMPACTS:
      return 'Компакты';
    case INamePriceItem.CROSSOVER:
      return 'Кроссовер';
    case INamePriceItem.SMALL:
      return 'Малые';
    case INamePriceItem.VAN:
      return 'ВАН';
    case INamePriceItem.MIDDLE_CLASS:
      return 'Средний класс';
    case INamePriceItem.TOP_CLASS:
      return 'Высший класс';
    default:
      return '';
  }
};
