import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputTextarea } from 'primereact/inputtextarea';

import { updateSettingsAction } from '../../actions/settings.action';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { updateEmail } from '../../slices/settings.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';

export const EmailPage = () => {
  const dispatch = useAppDispatch();

  const { email } = useAppSelector((store) => store.settings.list);

  const handleChange = (v: string) => {
    dispatch(updateEmail(v));
  };

  const handleSave = async () => {
    await dispatch(updateSettingsAction({ key: 'email', value: email }));
  };

  return (
    <ContainerTemplate>
      <Card title="Email" className={'border-2 border-yellow'}>
        <div>
          <InputTextarea
            className={'w-full'}
            id="headline"
            value={email ?? ''}
            onChange={({ target }) => handleChange(target.value)}
          />
        </div>
        <Button label="Сохранить" icon="pi pi-check" className={'mt-2'} onClick={handleSave} />
      </Card>
    </ContainerTemplate>
  );
};
