import React, { useEffect, useRef, useState } from 'react';

import { Toast } from 'primereact/toast';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { getAllContent, handlePayloadInfoAction } from '../actions/globall.action';
import { setErrorHandlerCallback } from '../api/handler';
import { SidebarComponent } from '../components/core-components/templates/sidebar.component';
import { HeaderComponent } from '../components/header.component';
import { BlogsContentPage } from '../pages/blog/blogs-content.page';
import { BlogsPage } from '../pages/blog/blogs.page';
import { CreateRedactBlogPage } from '../pages/blog/create-redact-blog.page';
import { TagsListPage } from '../pages/blog/tags-list.page';
import { CarPartsPage } from '../pages/car-parts/car-parts.page';
import { CarsListContentPage } from '../pages/cars/cars-content.page';
import { CarsPage } from '../pages/cars/cars.page';
import { CreateRedactCarPage } from '../pages/cars/create-redact-car.page';
import { CreateRedactFaqPage } from '../pages/faq/create-redact-faq.page';
import { FaqPage } from '../pages/faq/faq.page';
import { FormPage } from '../pages/form/form.page';
import { LoginPage } from '../pages/login/login.page';
import { AboutUsPage } from '../pages/main/about-us.page';
import { BannerPage } from '../pages/main/banner.page';
import { CarsContentPage } from '../pages/main/cars-content.page';
import { OurBenefitsPage } from '../pages/main/our-benefits.page';
import { ReviewsPage } from '../pages/main/reviews.page';
import { TestimonialsPage } from '../pages/main/testimonials.page';
import { VideoBlogPage } from '../pages/main/video-blog.page';
import { NotFoundPage } from '../pages/not-found/not-found.page';
import { PagesSeo } from '../pages/pages-ceo/pages-ceo.page';
import { PriceListPage } from '../pages/price-list/price-list.page';
import { CreateRedactServicePage } from '../pages/services/create-redact-service.page';
import { ServicesPage } from '../pages/services/services.page';
import { EmailPage } from '../pages/settings/email.page';
import { PhonePage } from '../pages/settings/phone.page';
import { SocialFacebookPage } from '../pages/settings/social-facebook.page';
import { SocialInstagramPage } from '../pages/settings/social-instagram.page';
import { SocialYoutubePage } from '../pages/settings/social-youtube.page';
import { SocialWhatsappPage } from '../pages/settings/social_whatsapp.page';
import { clearPayloadInfo } from '../slices/global-store.slice';
import { useAppDispatch, useAppSelector } from '../store/store';

export const AuthNavigate = () => {
  const dispatch = useAppDispatch();

  const payloadInfo = useAppSelector(({ globalStore }) => globalStore.payloadInfo);
  const toast = useRef<Toast>(null);

  const navigate = useNavigate();
  const tokenStorage = localStorage.getItem('token');
  const language = localStorage.getItem('language');

  const [isVisibleSidebar, setIsVisibleSidebar] = useState(false);

  useEffect(() => {
    if (!tokenStorage) return navigate('/login');
    if (!language) {
      localStorage.setItem('language', JSON.stringify({ code: 'en', name: 'English' }));
    }
    return navigate('/main/banner');
  }, [tokenStorage]);

  useEffect(() => {
    if (!tokenStorage) return;
    (async () => {
      setErrorHandlerCallback((statusCode: number) => {
        dispatch(handlePayloadInfoAction({ statusCode }));
      });
      await dispatch(getAllContent());
    })();
  }, [dispatch]);

  useEffect(() => {
    if (!payloadInfo) return;
    toast.current?.show(payloadInfo);
  }, [payloadInfo]);

  const onRemoveToast = () => {
    if (Array.isArray(payloadInfo) && payloadInfo.length > 1) return;
    dispatch(clearPayloadInfo());
  };

  const openSidebar = () => {
    setIsVisibleSidebar(true);
  };

  const closeSidebar = () => {
    setIsVisibleSidebar(false);
  };

  return (
    <>
      {payloadInfo && <Toast ref={toast} onRemove={onRemoveToast} />}
      {tokenStorage && (
        <HeaderComponent isVisibleSidebar={isVisibleSidebar} openSidebar={openSidebar} />
      )}
      <SidebarComponent closeSidebar={closeSidebar} isVisibleSidebar={isVisibleSidebar} />
      <Routes>
        {/*Login*/}
        <Route path="/login" element={<LoginPage />} />
        {/*Main Page*/}
        <Route path="/main/banner" element={<BannerPage />} />
        <Route path="/main/about-us" element={<AboutUsPage />} />
        <Route path="/main/testimonials" element={<TestimonialsPage />} />
        <Route path="/main/reviews" element={<ReviewsPage />} />
        <Route path="/main/video-blog" element={<VideoBlogPage />} />
        <Route path="/main/our-benefits" element={<OurBenefitsPage />} />
        <Route path="/main/cars" element={<CarsContentPage />} />
        {/*Settings*/}
        <Route path="/settings/social-instagram" element={<SocialInstagramPage />} />
        <Route path="/settings/social-facebook" element={<SocialFacebookPage />} />
        <Route path="/settings/social-youtube" element={<SocialYoutubePage />} />
        <Route path="/settings/email" element={<EmailPage />} />
        <Route path="/settings/phone" element={<PhonePage />} />
        <Route path="/settings/whatsapp" element={<SocialWhatsappPage />} />
        {/*Blog*/}
        <Route path="/blog/blog-list" element={<BlogsPage />} />
        <Route path="/blog/tags-list" element={<TagsListPage />} />
        <Route path="/blog/create-redact-blog" element={<CreateRedactBlogPage />} />
        <Route path="/blog/blogs/content" element={<BlogsContentPage />} />
        {/*Services*/}
        <Route path="/services/services-list" element={<ServicesPage />} />
        <Route path="/services/create-redact-service" element={<CreateRedactServicePage />} />
        {/*Cars*/}
        <Route path="/car/car-list" element={<CarsPage />} />
        <Route path="/car/create-redact-car" element={<CreateRedactCarPage />} />
        <Route path="/car/cars/content" element={<CarsListContentPage />} />
        {/*Form*/}
        <Route path="/form/car-part/content" element={<CarPartsPage />} />
        <Route path="/form/content" element={<FormPage />} />
        {/*Price List*/}
        <Route path="/price/price-list" element={<PriceListPage />} />
        {/*SEO*/}
        <Route path="/pages/pages-ceo" element={<PagesSeo />} />
        {/*FAQ*/}
        <Route path="/faq/faq-list" element={<FaqPage />} />
        <Route path="/faq/create-redact" element={<CreateRedactFaqPage />} />
        {/*Not Found Page*/}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};
