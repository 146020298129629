import { Button } from 'primereact/button';

import { getMoreBlogs } from '../../actions/blog.action';
import { BlogListComponent } from '../../components/blog/blog-list.component';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { useAppDispatch, useAppSelector } from '../../store/store';

export const BlogsPage = () => {
  const dispatch = useAppDispatch();

  const { list, total_count } = useAppSelector(({ blog }) => blog.blog);

  const isMoreBlogs = total_count > list.length;

  const handleUploadMore = async () => {
    await dispatch(getMoreBlogs());
  };

  return (
    <ContainerTemplate>
      <h1 className={'text-2xl text-center mb-10'}>Страница блогов</h1>

      <BlogListComponent list={list} />

      <div className={'w-full flex items-center justify-center mt-10'}>
        {isMoreBlogs && (
          <Button label="Загрузить еще" className="p-button-warning" onClick={handleUploadMore} />
        )}
      </div>
    </ContainerTemplate>
  );
};
