import { FC } from 'react';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import { IFaq } from '../../store/types';

export const FaqItemComponent: FC<IProps> = ({ item, openWarningModal, onClick }) => {
  const getTitleContent = () => {
    return <div className={'gap-2'}>{item.question}</div>;
  };

  const footer = (
    <div className="flex flex-wrap justify-content-end gap-2">
      <Button
        label="Редактировать"
        icon="pi pi-file-edit"
        onClick={() => onClick(item.id ? item.id : 0)}
        className={'w-full'}
      />
      <Button
        label="Удалить"
        severity="danger"
        icon={'pi pi-times'}
        onClick={() => openWarningModal(item.id ? item.id : 0)}
        className={'w-full'}
      />
    </div>
  );

  return (
    <div className="card flex justify-content-center justify-items-end">
      <Card
        title={getTitleContent}
        footer={footer}
        className="md:w-25rem flex flex-col justify-between"
      />
    </div>
  );
};

interface IProps {
  item: IFaq;
  openWarningModal: (id: number) => void;
  onClick: (id: number) => void;
}
