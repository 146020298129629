import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputTextarea } from 'primereact/inputtextarea';

import { updateSection } from '../../actions/main.action';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { updateVideoBlog } from '../../slices/main-content.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';

export const VideoBlogPage = () => {
  const dispatch = useAppDispatch();

  const { videos } = useAppSelector(({ main }) => main.sections);

  const handleUpdateBannerContent = (key: string, value: string) => {
    dispatch(updateVideoBlog({ ...videos, [key]: value }));
  };

  const handleSaveBanner = () => {
    dispatch(updateSection({ section: videos }));
  };

  return (
    <ContainerTemplate>
      <Card title="Видео блог контент" className={'border-2 border-yellow'}>
        <div>
          <label htmlFor="headline">Заголовок</label>
          <InputTextarea
            className={'w-full'}
            id="headline"
            value={videos.title ?? ''}
            onChange={({ target }) => handleUpdateBannerContent('title', target.value)}
          />
        </div>
        <div className="mt-2">
          <label htmlFor="description">Описание</label>
          <InputTextarea
            className={'w-full'}
            id="description"
            value={videos.description ?? ''}
            onChange={({ target }) => handleUpdateBannerContent('description', target.value)}
          />
        </div>
        <Button
          label="Сохранить"
          icon="pi pi-check"
          className={'mt-2'}
          onClick={handleSaveBanner}
        />
      </Card>
    </ContainerTemplate>
  );
};
