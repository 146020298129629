import React, { FC, useEffect } from 'react';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';

import { ILanguage } from '../../types';
import { selectLanguageAction } from '../actions/globall.action';
import { languages } from '../constants';
import { selectLanguage } from '../slices/global-store.slice';
import { useAppDispatch, useAppSelector } from '../store/store';

import { LogoComponent } from './core-components/images/logo.component';
import { ContainerHeaderTemplate } from './core-components/templates/container-header.template';

export const HeaderComponent: FC<IProps> = ({ isVisibleSidebar, openSidebar }) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { selectedLanguage } = useAppSelector(({ globalStore }) => globalStore);

  useEffect(() => {
    const language = localStorage.getItem('language');
    if (!language) return;
    dispatch(selectLanguage(JSON.parse(language)));
  }, []);

  const handleSelect = async (value: ILanguage) => {
    await dispatch(selectLanguageAction(value));
    location.reload();
  };

  return (
    <header className={'bg-[#000]'}>
      <ContainerHeaderTemplate>
        <div className="flex justify-between w-full py-4">
          <div className="flex">
            <LogoComponent onClick={() => navigate('/main/banner')} />

            <div className={'flex flex-col ml-12'}>
              <Dropdown
                value={selectedLanguage}
                onChange={(e) => handleSelect(e.value)}
                options={languages}
                optionLabel="name"
                placeholder="Выбрать язык"
                className="w-full md:w-14rem"
                defaultValue={selectedLanguage.code}
              />
            </div>
          </div>
          <Button
            icon={`${isVisibleSidebar ? 'pi pi-times' : 'pi pi-align-justify'}`}
            onClick={openSidebar}
            className={'text-black bg-yellow'}
          />
        </div>
      </ContainerHeaderTemplate>
    </header>
  );
};

interface IProps {
  isVisibleSidebar: boolean;
  openSidebar: () => void;
}
