import { ToastMessage } from 'primereact/toast';

export interface IStore {
  main: {
    sections: ISections;
    reviews: IReviews;
  };
  globalStore: {
    selectedLanguage: IGlobalSlice;
    payloadInfo: IPayloadInfo;
  };
  settings: { list: ISettings };
  blog: {
    blog: { list: IBlog[]; total_count: number };
    page: IPage;
    currentBlog: ICurrentBlog;
    tags: { list: ITag[] | [] };
    content: IContentTitleDescription;
  };
  car: {
    car: { list: ICar[]; total_count: number };
    page: IPage;
    currentCar: ICurrentCar;
    content: IContentTitleDescription;
  };
  services: {
    currentService: IService;
    services: { list: IService[]; total_count: number };
    page: IPage;
    favorites: {
      ids: number[] | [];
    };
  };
  carParts: {
    carParts: {
      list: ICarParts[] | [];
    };
  };
  formPage: {
    contact: IDefaultStackSection;
    whatsChecked: IDefaultStackSection;
  };
  faqPage: {
    list: IFaq[];
    current: IFaq;
  };
  priceList: {
    list: IPriceItem[];
    content: IPriceContent;
  };
  pages: {
    data: IPageSeo[];
  };
}

export interface IPageSeo {
  id: number;
  slug: string;
  title: string;
  header: string;
}

export interface IPriceContent {
  heading: IContent;
  description: IContent;
}

export interface IContent {
  title: string;
  description: string;
}

export interface IPriceItem {
  id: number;
  inspection_price_pln: number;
  autofit_price_pln: number;
  name: string;
  title: string;
  sample_cars: string;
}

export interface IFaq {
  id: number;
  answer: string;
  question: string;
}

export interface ICarParts {
  id: number;
  title: string;
  description: string;
  name: INameWhatCheckInCar;
}

export interface IContentTitleDescription {
  title: string;
  description: string;
}

export interface IService {
  id: number;
  image: string | null;
  header: string | null;
  title: string;
  slug: string;
  description: string;
  short_description: string;
  is_favorite: boolean;
}

export interface ICar {
  id?: number;
  car_name: string;
  image: null | string;
  description: string;
  price_pln: number;
  year: number;
  mileage: number;
  capacity: number;
  engine: number;
  engine_type: string;
  transmission: string;
  checked_text: string;
  verdict_text: string;
  certifications: string;
}

export interface ICurrentCar extends ICar {
  gallery: ICurrentCarGallery[] | null;
  header: string | null;
}

export interface ICurrentCarGallery {
  image_path: string;
  image_url: string;
}

export interface IImageItem {
  image_path: string;
  image_url: string;
}

export interface IPage {
  limit: number;
  count: number;
}

export interface IBlog {
  id?: number;
  image: string | null;
  title: string;
  author: string;
  slug: string;
  published_at: string;
  tags: ITag[] | null;
}

export interface IBlogForSend {
  id?: number;
  image: string | null;
  title: string;
  author: string;
  slug: string;
  published_at: string;
  tags: number[] | null;
  description: string;
}

export interface ITag {
  id: number;
  slug: string;
  tag: string;
}

export interface ICurrentBlog extends IBlog {
  description: string;
  header: string | null;
}

export interface ISettings {
  social_instagram: string;
  social_facebook: string;
  social_youtube: string;
  social_whatsapp: string;
  email: string;
  phone: string;
}

export type IPayloadInfo = ToastMessage | ToastMessage[] | null;

export interface ISections {
  banner: IDefaultStackSection;
  testimonials: IDefaultStackSection;
  videos: IDefaultStackSection;
  about: IDefaultStackSection;
  benefits: IDefaultStackSection;
  cars: IDefaultStackSection;
}

export interface IDefaultStackSection extends IDefaultStack {
  subtitle: string | null;
  description: string;
  title: string;
  media: string;
  gallery: IImageItem[] | [];
  id: number;
  page_id: number;
  name: string;
}

export type ListItemType = 'list_item_one' | 'list_item_two' | 'list_item_three';
export type ListItemContentType = 'heading' | 'description';

export interface IDefaultStack {
  list_item_one: IDefaultListObject;
  list_item_two: IDefaultListObject;
  list_item_three: IDefaultListObject;
}

export interface IReviews {
  list: IReviewItem[] | [];
}

export interface IReviewItem {
  id: number;
  url: string;
}

export type IDefaultListObject = { description: string; heading: string };

export interface IGlobalSlice {
  code: string;
  name: string;
}

export enum INamePriceItem {
  SMALL = 'small',
  COMPACTS = 'compacts',
  VAN = 'VAN',
  CROSSOVER = 'crossover',
  MIDDLE_CLASS = 'middle-class',
  TOP_CLASS = 'top-class',
}



export enum INameWhatCheckInCar {
  CHECKING_VEHICLE_DOCUMENTS = 'checking-vehicle-documents',
  TRANSMISSIONS_CLUTCHES = 'transmissions-clutches',
  AGGREGATE_OPERATION_CORRECTIONS = 'aggregate-operation-corrections',
  IGNITION_SYSTEMS = 'ignition-systems',
  ASSESSMENT_STATE_BODY = 'assessment-state-body',
  CHECKING_CONTROL = 'checking-control',
  READING_ENGINE_OPERATING = 'reading-engine-operating',
  FUEL_SYSTEM = 'fuel-system',
  BRAKE_SYSTEM = 'brake-system',
  ASSESSMENT_STATE_CHASSIS = 'assessment-state-chassis',
  MANDATORY_TEST_DRIVE = 'mandatory-test-drive',
}


