import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGlobalSlice, IPayloadInfo, IStore } from '../store/types';

const initialState = {
  payloadInfo: null,
  selectedLanguage: { code: 'EN', name: 'English' },
} as IStore['globalStore'];

export const globalStoreSlice = createSlice({
  initialState,
  name: 'globalStore',
  reducers: {
    clearPayloadInfo: (state) => {
      // @ts-ignore
      state.payloadInfo = initialState.payloadInfo;
    },
    selectLanguage: (state, action: PayloadAction<IGlobalSlice>) => {
      state.selectedLanguage = action.payload;
    },
    setPayloadInfo: (state, action: PayloadAction<IPayloadInfo>) => {
      // @ts-ignore
      state.payloadInfo = action.payload;
    },
  },
});

export const { selectLanguage, setPayloadInfo, clearPayloadInfo } = globalStoreSlice.actions;
