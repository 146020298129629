import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAllServices, getAllServicesFavorites, getService } from '../actions/services.action';
import { IService, IStore } from '../store/types';

const initialState = {
  currentService: {
    description: '',
    header: null,
    id: 0,
    image: null,
    is_favorite: false,
    short_description: '',
    slug: '',
    title: '',
  },
  favorites: {
    ids: [],
  },
  page: {
    count: 20,
    limit: 20,
  },
  services: {
    list: [],
    total_count: 0,
  },
} as IStore['services'];

export const servicesSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      getAllServices.fulfilled,
      (state, action: PayloadAction<{ data: IService[] | []; total_count: number }>) => {
        if (!action.payload) return;
        state.services.list = action.payload.data;
        state.services.total_count = action.payload.total_count;
      },
    );
    builder.addCase(getService.fulfilled, (state, action: PayloadAction<IService>) => {
      state.currentService = action.payload;
    });
    builder.addCase(
      getAllServicesFavorites.fulfilled,
      (state, action: PayloadAction<{ ids: number[] | [] }>) => {
        state.favorites.ids = action.payload.ids ? action.payload.ids : [];
      },
    );
  },
  initialState,
  name: 'services',
  reducers: {
    clearService: (state) => {
      state.currentService = initialState.currentService;
    },
    setImageToCurrentService: (state, action: PayloadAction<string>) => {
      state.currentService.image = action.payload;
    },
    updateDescription: (state, action: PayloadAction<string>) => {
      state.currentService.description = action.payload;
    },
    updateHeader: (state, action: PayloadAction<string>) => {
      state.currentService.header = action.payload;
    },
    updateIsFavorite: (state) => {
      state.currentService.is_favorite = !state.currentService.is_favorite;
    },
    updateServicesFavorites: (state, action: PayloadAction<number[] | []>) => {
      state.favorites.ids = action.payload;
    },
    updateShortDescription: (state, action: PayloadAction<string>) => {
      state.currentService.short_description = action.payload;
    },
    updateTitle: (state, action: PayloadAction<string>) => {
      state.currentService.title = action.payload;
    },
    uploadMoreServices: (state, action: PayloadAction<number>) => {
      state.page.limit = action.payload;
    },
  },
});

export const {
  uploadMoreServices,
  clearService,
  setImageToCurrentService,
  updateDescription,
  updateTitle,
  updateShortDescription,
  updateServicesFavorites,
  updateHeader,
} = servicesSlice.actions;
