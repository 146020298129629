import React, { FC, useRef } from 'react';

import {
  FileUpload,
  FileUploadBeforeSendEvent,
  FileUploadUploadEvent,
} from 'primereact/fileupload';
import { Toast } from 'primereact/toast';

import { setImageToCurrentBlog } from '../../slices/blog.slice';
import { useAppDispatch } from '../../store/store';

export const ImageBlogComponent: FC<IProps> = ({ image, setImagePath }) => {
  const dispatch = useAppDispatch();

  const token = localStorage.getItem('token');

  const toast = useRef<Toast>(null);

  const onUpload = (e: FileUploadUploadEvent) => {
    const imageData: { image_path: string; image_url: string } = JSON.parse(e.xhr.response);
    dispatch(setImageToCurrentBlog(imageData.image_url));
    setImagePath(imageData.image_path);
    if (!toast.current) return;
    toast.current.show({ detail: 'File Uploaded', severity: 'info', summary: 'Success' });
  };

  const onBeforeSend = (event: FileUploadBeforeSendEvent) => {
    event.xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  };

  return (
    <div className="card flex flex-col justify-content-center mb-10">
      <div className={'w-full flex items-center'}>
        {image ? (
          <div className={'w-full flex justify-content-center'}>
            <img alt="Card" src={image} />
          </div>
        ) : (
          <div className={'w-full flex justify-content-center'}>
            <Toast ref={toast}></Toast>
            <FileUpload
              onBeforeSend={onBeforeSend}
              mode="basic"
              name="image"
              url="https://devdata.autorevizor.com.pl/api/admin/blog/upload_image"
              accept="image/*"
              maxFileSize={300000000}
              onUpload={onUpload}
              chooseLabel={'Выбрать изображение'}
            />
          </div>
        )}
      </div>

      {image ? (
        <div className={'w-full mt-10 flex justify-content-center'}>
          <Toast ref={toast}></Toast>
          <FileUpload
            onBeforeSend={onBeforeSend}
            mode="basic"
            name="image"
            url="https://devdata.autorevizor.com.pl/api/admin/blog/upload_image"
            accept="image/*"
            maxFileSize={300000000}
            onUpload={onUpload}
            chooseLabel={'Выбрать изображение'}
          />
        </div>
      ) : null}
    </div>
  );
};

interface IProps {
  image: string | null;
  setImagePath: (e: string) => void;
}
