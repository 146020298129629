import { FC } from 'react';

import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';

import { IBlog } from '../../store/types';

export const BlogItemComponent: FC<IProps> = ({ content, onClick, openWarningModal }) => {
  const header = content.image ? (
    <img alt="Card" className={'h-[380px] object-cover'} src={content.image} />
  ) : (
    <div className={'h-[380px]'} />
  );
  const date = format(new Date(content.published_at), 'MM.dd.yyyy');
  const getTitleContent = () => {
    return (
      <div className={'gap-2'}>
        <div className={'flex items-center justify-between mb-2'}>
          {content.tags?.length ? <Tag value={content.tags[0].tag} /> : <div />}
          <p className={'text-greyText text-sm'}>{date}</p>
        </div>
        {content.title}
      </div>
    );
  };

  const footer = (
    <div className="flex flex-wrap justify-content-end gap-2">
      <Button
        label="Редактировать"
        icon="pi pi-file-edit"
        onClick={() => onClick(content.id ? content.id : 0)}
        className={'w-full'}
      />
      <Button
        label="Удалить"
        severity="danger"
        icon={'pi pi-times'}
        onClick={() => openWarningModal(content.id ? content.id : 0)}
        className={'w-full'}
      />
    </div>
  );

  return (
    <div className="card flex justify-content-center justify-items-end">
      <Card
        title={getTitleContent}
        footer={footer}
        header={header}
        className="md:w-25rem flex flex-col justify-between"
      />
    </div>
  );
};

interface IProps {
  content: IBlog;
  onClick: (id: number) => void;
  openWarningModal: (id: number) => void;
}
