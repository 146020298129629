import React, { FC } from 'react';

import {
  FileUpload,
  FileUploadBeforeSendEvent,
  FileUploadUploadEvent,
} from 'primereact/fileupload';

export const FileUploadComponent: FC<IProps> = ({ url, onUpload }) => {
  const token = localStorage.getItem('token');

  const onBeforeSend = (event: FileUploadBeforeSendEvent) => {
    event.xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  };

  const chooseOptions = {
    className: 'custom-choose-btn p-button-rounded p-button-outlined',
    icon: 'pi pi-fw pi-images',
    iconOnly: true,
  };
  const uploadOptions = {
    className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined',
    icon: 'pi pi-fw pi-cloud-upload',
    iconOnly: true,
  };
  const cancelOptions = {
    className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined',
    icon: 'pi pi-fw pi-times',
    iconOnly: true,
  };

  return (
    <div className="card mb-10">
      <FileUpload
        name="images[]"
        onBeforeSend={onBeforeSend}
        url={`https://devdata.autorevizor.com.pl/api/admin/${url}`}
        multiple
        accept="image/*"
        maxFileSize={300000000}
        onUpload={onUpload}
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
        emptyTemplate={<p className="m-0">Здесь будут отображатся файлы которые вы добавите</p>}
      />
    </div>
  );
};

interface IProps {
  url: string;
  onUpload: (e: FileUploadUploadEvent) => void;
}
