import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAllCarParts } from '../actions/car-parts.action';
import { ICarParts, IStore } from '../store/types';

const initialState = {
  carParts: {
    list: [],
  },
} as IStore['carParts'];

export const carPartsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      getAllCarParts.fulfilled,
      (state, action: PayloadAction<{ data: ICarParts[] }>) => {
        state.carParts.list = action.payload.data;
      },
    );
  },
  initialState,
  name: 'carParts',
  reducers: {
    updateCarPartDescription: (state, action: PayloadAction<{ id: number; value: string }>) => {
      state.carParts.list = state.carParts.list.map((item) =>
        item.id === action.payload.id ? { ...item, description: action.payload.value } : item,
      );
    },
    updateCarPartTitle: (state, action: PayloadAction<{ id: number; value: string }>) => {
      state.carParts.list = state.carParts.list.map((item) =>
        item.id === action.payload.id ? { ...item, title: action.payload.value } : item,
      );
    },
  },
});

export const { updateCarPartDescription, updateCarPartTitle } = carPartsSlice.actions;
