import React, { useState } from 'react';

import { Button } from 'primereact/button';
import { Editor, EditorTextChangeEvent } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';

import { createService, updateService } from '../../actions/services.action';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { EditorRedactorComponent } from '../../components/core-components/templates/text-redactors/editor-redactor.component';
import { ImageServiceComponent } from '../../components/services/image-service.component';
import { Slugify } from '../../helpers';
import {
  updateDescription,
  updateTitle,
  clearService,
  updateShortDescription,
  updateHeader,
} from '../../slices/services.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';

export const CreateRedactServicePage = () => {
  const dispatch = useAppDispatch();

  const navigation = useNavigate();
  const { state }: { state?: { id: number } } = useLocation();

  const { currentService } = useAppSelector(({ services }) => services);

  const [imagePath, setImagePath] = useState<string>('');

  const handleRedirectToList = () => {
    dispatch(clearService());
    navigation('/services/services-list');
  };

  const handleSave = () => {
    const cutUrlImage = currentService.image
      ? currentService.image
          .replace('https://devdata.autorevizor.com.pl/storage', '')
          .replace('https://data.autorevizor.com.pl/storage/', '')
      : null;
    if (state) {
      dispatch(updateService({ ...currentService, image: imagePath ? imagePath : cutUrlImage }));
      handleRedirectToList();
      return;
    }
    dispatch(
      createService({
        ...currentService,
        image: imagePath ? imagePath : null,
        slug: Slugify(currentService.title),
      }),
    );
    handleRedirectToList();
  };

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-code-block" aria-label="Code"></button>
      </span>
    );
  };

  const header = renderHeader();

  return (
    <ContainerTemplate>
      <ImageServiceComponent image={currentService.image} setImagePath={setImagePath} />

      <div className={'grid grid-cols-12 gap-4 mb-10'}>
        <div className="flex flex-column col-span-8">
          <div className={'flex'}>
            <label htmlFor="title">Заголовок</label>
            <p className="text-red-600">*</p>
          </div>
          <InputText
            id="title"
            aria-describedby="title-help"
            value={currentService.title ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(updateTitle(e.target.value))
            }
          />
        </div>
        <div className="flex flex-column col-span-4">
          <label htmlFor="title">Краткий заголовок</label>
          <InputText
            id="title"
            aria-describedby="title-help"
            value={currentService.short_description ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(updateShortDescription(e.target.value))
            }
          />
        </div>
      </div>

      <EditorRedactorComponent
        title={'Описание сервиса'}
        value={currentService.description ?? ''}
        onChange={(v) => dispatch(updateDescription(v))}
        id={'service_description'}
      />

      <div className={'flex flex-col w-full mt-10'}>
        <div className={'flex items-start'}>
          <label htmlFor={'blog_ceo'}>SEO Сервиса</label>
        </div>
        <Editor
          id={'blog_ceo'}
          value={currentService.header ?? ''}
          onTextChange={(e: EditorTextChangeEvent) =>
            dispatch(updateHeader(e.htmlValue ? e.htmlValue : ''))
          }
          style={{ height: '320px' }}
          headerTemplate={header}
        />
      </div>

      <Button
        label="Сохранить"
        icon="pi pi-check"
        className={'w-full mt-10'}
        onClick={handleSave}
      />
    </ContainerTemplate>
  );
};
