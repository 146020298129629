import axios from 'axios';

import { ILanguage } from '../../types';

export const instance = axios.create({
  baseURL: 'https://devdata.autorevizor.com.pl/api/admin/',
  headers: {
    'Accept-Language': 'uk',
    'Content-Type': 'application/json',
  },
  timeout: 3000,
});

const token: string | null = localStorage.getItem('token');
const language: ILanguage = JSON.parse(
  localStorage.getItem('language') ?? '{"code":"en","name":"English"}',
);
export const instanceAuthorization = axios.create({
  baseURL: 'https://devdata.autorevizor.com.pl/api/admin/',
  headers: {
    'Accept-Language': language.code,
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
  timeout: 3000,
});
