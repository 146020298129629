import { configureStore } from '@reduxjs/toolkit';
import { useSelector as rawUseSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';

import { blogSlice } from '../slices/blog.slice';
import { carPartsSlice } from '../slices/car-parts.slice';
import { carSlice } from '../slices/car.slice';
import { faqPageSlice } from '../slices/faq.slice';
import { formPageSlice } from '../slices/form.slice';
import { globalStoreSlice } from '../slices/global-store.slice';
import { mainSlice } from '../slices/main-content.slice';
import { pagesSlice } from '../slices/pages.slice';
import { priceListSlice } from '../slices/price-list.slice';
import { servicesSlice } from '../slices/services.slice';
import { settingsSlice } from '../slices/settings.slice';

export const store = configureStore({
  reducer: {
    blog: blogSlice.reducer,
    car: carSlice.reducer,
    carParts: carPartsSlice.reducer,
    faqPage: faqPageSlice.reducer,
    formPage: formPageSlice.reducer,
    globalStore: globalStoreSlice.reducer,
    main: mainSlice.reducer,
    pages: pagesSlice.reducer,
    priceList: priceListSlice.reducer,
    services: servicesSlice.reducer,
    settings: settingsSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
