import { FC, ReactNode } from 'react';

export const ContainerTemplate: FC<IProps> = ({ children }) => {
  return (
    <div
      className={
        'w-full px-5 md:px-10 lg:px-16 xl:px-28 md:pt-10 lg:pt-16 xl:pt-20 md:pb-10 lg:pb-16 xl:pb-20'
      }
    >
      {children}
    </div>
  );
};

interface IProps {
  children: ReactNode;
}
