import { createAsyncThunk } from '@reduxjs/toolkit';
import { ToastMessage } from 'primereact/toast';

import { ILanguage } from '../../types';
import { selectLanguage, setPayloadInfo } from '../slices/global-store.slice';
import { AppDispatch } from '../store/store';

import { logoutAction } from './auth.action';
import { getAllBlogs, getAllTags, getContentBlog } from './blog.action';
import { getAllCarParts } from './car-parts.action';
import { getAllCars, getContentCar } from './car.action';
import { getAllFaq } from './faq.action';
import { getContentFormParts } from './form.action';
import { getAllHomeContent, getAllReviews } from './main.action';
import { getAllPages } from './pages.action';
import { getAllPriceList, getPriceContent } from './price-list.action';
import { getAllServices, getAllServicesFavorites } from './services.action';
import { getAllSettings } from './settings.action';

export const handlePayloadInfoAction = createAsyncThunk<
  void,
  { statusCode: number },
  { dispatch: AppDispatch }
>('payload/handle', async ({ statusCode }, { dispatch }) => {
  const getPayloadInfoByStatus = () => {
    switch (statusCode) {
      case 401:
        setTimeout(() => dispatch(logoutAction()), 3500);
        return {
          detail: 'You are not authorized',
          life: 3000,
          severity: 'error',
          summary: 'Error',
        };
      case 404:
        return {
          detail: 'The requested resource is not found',
          life: 3000,
          severity: 'error',
          summary: 'Error',
        };
      case 405:
        return {
          detail: 'Bad request',
          life: 3000,
          severity: 'error',
          summary: 'Error',
        };
      case 422:
        return {
          detail: 'Wrong data',
          life: 3000,
          severity: 'error',
          summary: 'Error',
        };
      case 500:
        return {
          detail: 'Internal server error',
          life: 3000,
          severity: 'error',
          summary: 'Error',
        };
      case 200:
        return {
          detail: 'Data saved successfully',
          life: 3000,
          severity: 'success',
          summary: 'Success',
        };

      default:
        return {
          detail: 'Internal server error',
          life: 3000,
          severity: 'error',
          summary: 'Error',
        };
    }
  };

  dispatch(setPayloadInfo(getPayloadInfoByStatus() as ToastMessage | ToastMessage[]));
});

export const selectLanguageAction = createAsyncThunk<void, ILanguage, { dispatch: AppDispatch }>(
  'select-language',
  async (data, { dispatch }) => {
    dispatch(selectLanguage(data));
    localStorage.setItem('language', JSON.stringify(data));
    await dispatch(getAllContent());
  },
);

export const getAllContent = createAsyncThunk<void, void, { dispatch: AppDispatch }>(
  'content-all/get',
  async (_, { dispatch }) => {
    await dispatch(getAllPages());
    await dispatch(getAllHomeContent());
    await dispatch(getContentBlog());
    await dispatch(getContentCar());
    await dispatch(getAllSettings());
    await dispatch(getAllReviews());
    await dispatch(getAllBlogs());
    await dispatch(getAllTags());
    await dispatch(getAllCars());
    await dispatch(getAllServices());
    await dispatch(getAllServicesFavorites());
    await dispatch(getAllCarParts());
    await dispatch(getContentFormParts());
    await dispatch(getAllFaq());
    await dispatch(getAllPriceList());
    await dispatch(getPriceContent());
  },
);
