import React, { FC } from 'react';

import { format } from 'date-fns';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';

import { updateDate } from '../../slices/blog.slice';
import { useAppDispatch } from '../../store/store';

export const CalendarBlogComponent: FC<IProps> = ({ published_at }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="card">
      <span className="p-float-label flex flex-col justify-end">
        <Calendar
          inputId="publish_at"
          value={new Date(published_at)}
          dateFormat={'dd.mm.yy'}
          onChange={(e: CalendarChangeEvent) => {
            if (!e.value) return;
            dispatch(updateDate(format(new Date(e.value.toString()), 'yyyy-MM-dd h:mm:ss')));
          }}
        />
        <label htmlFor="publish_at">Дата публикации</label>
      </span>
    </div>
  );
};

interface IProps {
  published_at: string;
}
