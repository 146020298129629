import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputTextarea } from 'primereact/inputtextarea';

import { updateSection } from '../../actions/main.action';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { updateOurBenefits } from '../../slices/main-content.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { ListItemContentType, ListItemType } from '../../store/types';

export const OurBenefitsPage = () => {
  const dispatch = useAppDispatch();

  const { benefits } = useAppSelector(({ main }) => main.sections);

  const handleUpdateOurBenefitsList = (key: [ListItemType, ListItemContentType], value: string) => {
    dispatch(
      updateOurBenefits({ ...benefits, [key[0]]: { ...benefits[key[0]], [key[1]]: value } }),
    );
  };

  const handleUpdateOurBenefits = (key: string, value: string) => {
    dispatch(updateOurBenefits({ ...benefits, [key]: value }));
  };

  const handleSaveOurBenefits = () => {
    dispatch(updateSection({ section: benefits }));
  };

  return (
    <ContainerTemplate>
      <Card title="Наши преимущества">
        <Card title="Главный заголовок и описание" className={'border-2 border-yellow'}>
          <div className="mt-2">
            <label htmlFor="headline">Заголовок</label>
            <InputTextarea
              className={'w-full'}
              id="headline"
              value={benefits.title ? benefits.title : ''}
              onChange={({ target }) => handleUpdateOurBenefits('title', target.value)}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="description">Описание</label>
            <InputTextarea
              className={'w-full'}
              id="description"
              value={benefits.description ? benefits.description : ''}
              onChange={({ target }) => handleUpdateOurBenefits('description', target.value)}
            />
          </div>
        </Card>
        {/*First block*/}
        <Card title="Первый блок" className={'border-2 border-yellow mt-10'}>
          <div className="mt-2">
            <label htmlFor="headline">Заголовок</label>
            <InputTextarea
              className={'w-full'}
              id="headline"
              value={benefits.list_item_one?.heading ?? ''}
              onChange={({ target }) =>
                handleUpdateOurBenefitsList(['list_item_one', 'heading'], target.value)
              }
            />
          </div>
          <div className="mt-2">
            <label htmlFor="description">Описание</label>
            <InputTextarea
              className={'w-full'}
              id="description"
              value={benefits.list_item_one?.description ?? ''}
              onChange={({ target }) =>
                handleUpdateOurBenefitsList(['list_item_one', 'description'], target.value)
              }
            />
          </div>
        </Card>

        {/*Second block*/}
        <Card title="Второй блок" className={'border-2 border-yellow mt-10'}>
          <div className="mt-2">
            <label htmlFor="headline">Заголовок</label>
            <InputTextarea
              className={'w-full'}
              id="headline"
              value={benefits.list_item_two?.heading ?? ''}
              onChange={({ target }) =>
                handleUpdateOurBenefitsList(['list_item_two', 'heading'], target.value)
              }
            />
          </div>
          <div className="mt-2">
            <label htmlFor="description">Описание</label>
            <InputTextarea
              className={'w-full'}
              id="description"
              value={benefits.list_item_two?.description ?? ''}
              onChange={({ target }) =>
                handleUpdateOurBenefitsList(['list_item_two', 'description'], target.value)
              }
            />
          </div>
        </Card>

        {/*Third block*/}
        <Card title="Третий блок" className={'border-2 border-yellow mt-10'}>
          <div className="mt-2">
            <label htmlFor="headline">Заголовок</label>
            <InputTextarea
              className={'w-full'}
              id="headline"
              value={benefits.list_item_three?.heading ?? ''}
              onChange={({ target }) =>
                handleUpdateOurBenefitsList(['list_item_three', 'heading'], target.value)
              }
            />
          </div>
          <div className={'mt-2'}>
            <label htmlFor="description">Описание</label>
            <InputTextarea
              className={'w-full'}
              id="description"
              value={benefits.list_item_three?.description ?? ''}
              onChange={({ target }) =>
                handleUpdateOurBenefitsList(['list_item_three', 'description'], target.value)
              }
            />
          </div>
        </Card>

        <Button
          label="Сохранить"
          icon="pi pi-check"
          className={'mt-2'}
          onClick={handleSaveOurBenefits}
        />
      </Card>
    </ContainerTemplate>
  );
};
