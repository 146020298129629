import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../api/api';
import { uploadMoreCars } from '../slices/car.slice';
import { setPayloadInfo } from '../slices/global-store.slice';
import { AppDispatch } from '../store/store';
import { IContentTitleDescription, ICar, ICurrentCar, IStore } from '../store/types';

export const getAllCars = createAsyncThunk<
  { data: ICar[] | []; total_count: number },
  void,
  { state: IStore }
>('cars/get', async (_, { getState }) => {
  const { limit } = getState().blog.page;
  const { data } = await api.cars.getAllCars(limit);
  if (!data) throw 'error';

  return data;
});

export const getMoreCars = createAsyncThunk<void, void, { state: IStore; dispatch: AppDispatch }>(
  'more-cars/get',
  async (_, { getState, dispatch }) => {
    const { limit, count } = getState().blog.page;
    const { total_count, list } = getState().blog.blog;

    const countOfMoreBlogs = total_count - list.length >= limit ? limit + count : total_count;

    dispatch(uploadMoreCars(countOfMoreBlogs));
    dispatch(getAllCars());
  },
);

export const getCar = createAsyncThunk<ICurrentCar, { id: number }>(
  'one-car/get',
  async ({ id }) => {
    const { data } = await api.cars.getCar(id);
    if (!data) throw 'error';
    return data.data;
  },
);

export const createCar = createAsyncThunk<void, ICar, { dispatch: AppDispatch }>(
  'create-car/post',
  async (carData, { dispatch }) => {
    await api.cars.createCar(carData);

    dispatch(getAllCars());
  },
);

export const updateCar = createAsyncThunk<void, ICurrentCar, { dispatch: AppDispatch }>(
  'update-car/post',
  async (carData, { dispatch }) => {
    await api.cars.updateCar({
      ...carData,
      image: carData.gallery ? carData.gallery[0].image_path : null,
    });

    dispatch(getAllCars());
  },
);

export const deleteCar = createAsyncThunk<void, { id: number }, { dispatch: AppDispatch }>(
  'delete-car/post',
  async ({ id }, { dispatch }) => {
    await api.cars.deleteCar({ id });

    dispatch(getAllCars());
  },
);

export const getContentCar = createAsyncThunk<IContentTitleDescription, void>(
  'content-car/get',
  async () => {
    const { data } = await api.cars.getContentCars();
    if (!data) throw 'error';
    return data.data.heading;
  },
);

export const updateSection = createAsyncThunk<
  void,
  { section: IContentTitleDescription },
  { dispatch: AppDispatch }
>('update/section', async ({ section }, { dispatch }) => {
  const data = await api.home.updateSection(section);
  if (data?.response?.status > 299) throw 'error';
  dispatch(
    setPayloadInfo({
      detail: 'Section has been saved',
      life: 3000,
      severity: 'success',
      summary: 'Success',
    }),
  );

  await dispatch(getContentCar());
});
