import { Button } from 'primereact/button';

import { getMoreCars } from '../../actions/car.action';
import { CarsListComponent } from '../../components/car/cars-list.component';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { useAppDispatch, useAppSelector } from '../../store/store';

export const CarsPage = () => {
  const dispatch = useAppDispatch();

  const { list, total_count } = useAppSelector(({ car }) => car.car);

  const isMoreBlogs = total_count > list.length;

  const handleUploadMore = async () => {
    await dispatch(getMoreCars());
  };

  return (
    <ContainerTemplate>
      <h1 className={'text-2xl text-center mb-10'}>Страница машин</h1>

      <CarsListComponent list={list} />

      <div className={'w-full flex items-center justify-center mt-10'}>
        {isMoreBlogs && (
          <Button label="Загрузить еще" className="p-button-warning" onClick={handleUploadMore} />
        )}
      </div>
    </ContainerTemplate>
  );
};
