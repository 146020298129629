import { useRef } from 'react';

import { FileUpload, FileUploadBeforeSendEvent } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';

import { getAllReviews, removeReviewAction } from '../../actions/main.action';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { ReviewsItemsComponent } from '../../components/reviews-components/reviews-items.component';
import { useAppDispatch, useAppSelector } from '../../store/store';

export const ReviewsPage = () => {
  const dispatch = useAppDispatch();
  const { list } = useAppSelector(({ main }) => main.reviews);

  const token = localStorage.getItem('token');

  const toast = useRef<Toast>(null);

  const onUpload = async () => {
    if (!toast.current) return;
    toast.current.show({ detail: 'File Uploaded', severity: 'info', summary: 'Success' });
    await dispatch(getAllReviews());
  };

  const onBeforeSend = (event: FileUploadBeforeSendEvent) => {
    event.xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  };

  const onDelete = async (id: number) => {
    await dispatch(removeReviewAction({ id }));
  };

  return (
    <ContainerTemplate>
      <div className="card flex justify-content-center fixed z-50 bottom-12">
        <Toast ref={toast}></Toast>
        <FileUpload
          chooseLabel={'Выбрать'}
          onBeforeSend={onBeforeSend}
          mode="basic"
          name="image"
          url="https://devdata.autorevizor.com.pl/api/admin/testimonials/add"
          accept="image/*"
          maxFileSize={300000000}
          onUpload={onUpload}
        />
      </div>

      <ReviewsItemsComponent items={list} onDelete={onDelete} />
    </ContainerTemplate>
  );
};
