import { FC } from 'react';

export const LogoComponent: FC<IPropsLogo> = ({ width = 92, height = 56, onClick }) => {
  return (
    <div onClick={onClick} className={'cursor-pointer'}>
      <img src={require('../../../images/logo.png')} alt="Logo" width={width} height={height} />
    </div>
  );
};

interface IPropsLogo {
  onClick: () => void;
  width?: number;
  height?: number;
}
