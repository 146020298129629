import React, { useState } from 'react';

import { Button } from 'primereact/button';
import { Editor, EditorTextChangeEvent } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';

import { createBlog, updateBlog } from '../../actions/blog.action';
import { CalendarBlogComponent } from '../../components/blog/calendar-blog.component';
import { ImageBlogComponent } from '../../components/blog/image-blog.component';
import { TagCheckboxItemComponent } from '../../components/blog/tag-checkbox-item.component';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { EditorRedactorComponent } from '../../components/core-components/templates/text-redactors/editor-redactor.component';
import {
  clearBlog,
  updateDescription,
  updateHeader,
  updateTag,
  updateTitle,
} from '../../slices/blog.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { ITag } from '../../store/types';

export const CreateRedactBlogPage = () => {
  const dispatch = useAppDispatch();

  const navigation = useNavigate();
  const { state }: { state?: { id: number } } = useLocation();

  const { currentBlog, tags } = useAppSelector(({ blog }) => blog);

  const { description, image, title, published_at } = currentBlog;
  const [imagePath, setImagePath] = useState<string>('');

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-code-block" aria-label="Code"></button>
      </span>
    );
  };

  const header = renderHeader();

  const handleRedirectToList = () => {
    dispatch(clearBlog());
    navigation('/blog/blog-list');
  };

  const handleSave = () => {
    const idList = currentBlog.tags ? currentBlog.tags.map(({ id }) => id) : null;

    if (state) {
      dispatch(
        updateBlog({ id: state.id, image: imagePath?.length ? imagePath : null, tags: idList }),
      );
      handleRedirectToList();
      return;
    }
    dispatch(createBlog({ imagePath, tags: idList }));
    handleRedirectToList();
  };

  const toggleCheck = (tagData: ITag) => {
    if (!currentBlog.tags) {
      dispatch(updateTag([tagData]));
      return;
    }
    const isElementInList = currentBlog.tags?.some((tagItem) => tagData.id === tagItem.id);
    if (isElementInList) {
      dispatch(updateTag(currentBlog.tags.filter((tagItem) => tagItem.id !== tagData.id)));
      return;
    }
    dispatch(updateTag([...currentBlog.tags, tagData]));
  };

  return (
    <ContainerTemplate>
      <ImageBlogComponent image={image} setImagePath={setImagePath} />

      <div className={'grid grid-cols-12 gap-4 mb-10'}>
        <div className="flex flex-column col-span-9">
          <div className={'flex items-start'}>
            <label htmlFor="year">Заголовок</label>
            <p className="text-red-600">*</p>
          </div>
          <InputText
            id="title"
            aria-describedby="title-help"
            value={title ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(updateTitle(e.target.value))
            }
          />
        </div>
        <div className={'col-span-3 flex flex-col justify-end'}>
          <CalendarBlogComponent published_at={published_at} />
        </div>
      </div>
      <div className={'flex flex-wrap mb-10'}>
        {tags.list.map((item, key) => (
          <TagCheckboxItemComponent
            item={item}
            isChecked={
              currentBlog.tags ? currentBlog.tags?.some((tagItem) => item.id === tagItem.id) : false
            }
            key={key}
            toggleCheck={toggleCheck}
          />
        ))}
      </div>

      <EditorRedactorComponent
        title={'Описание Блога'}
        value={description ?? ''}
        onChange={(v) => dispatch(updateDescription(v))}
        id={'blog_description'}
      />

      <div className={'flex flex-col w-full mt-10'}>
        <div className={'flex items-start'}>
          <label htmlFor={'blog_ceo'}>SEO Блога</label>
        </div>
        <Editor
          id={'blog_ceo'}
          value={currentBlog.header ?? ''}
          onTextChange={(e: EditorTextChangeEvent) =>
            dispatch(updateHeader(e.htmlValue ? e.htmlValue : ''))
          }
          style={{ height: '320px' }}
          headerTemplate={header}
        />
      </div>

      <Button
        label="Сохранить"
        icon="pi pi-check"
        className={'w-full mt-10'}
        onClick={handleSave}
      />
    </ContainerTemplate>
  );
};
