import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAllFaq } from '../actions/faq.action';
import { IFaq, IStore } from '../store/types';

const initialState = {
  current: {
    answer: '',
    id: 0,
    question: '',
  },
  list: [],
} as IStore['faqPage'];

export const faqPageSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getAllFaq.fulfilled, (state, action: PayloadAction<{ data: IFaq[] }>) => {
      state.list = action.payload.data;
    });
  },
  initialState,
  name: 'faqPage',
  reducers: {
    addCurrentFaq: (state, action: PayloadAction<IFaq>) => {
      state.current = action.payload;
    },
    clearCurrent: (state) => {
      state.current = initialState.current;
    },
    updateAnswer: (state, action: PayloadAction<string>) => {
      state.current.answer = action.payload;
    },
    updateListItem: (state, action: PayloadAction<IFaq>) => {
      state.list = state.list.map((item) =>
        item.id === action.payload.id ? action.payload : item,
      );
    },
    updateQuestion: (state, action: PayloadAction<string>) => {
      state.current.question = action.payload;
    },
  },
});

export const { updateListItem, clearCurrent, updateAnswer, addCurrentFaq, updateQuestion } =
  faqPageSlice.actions;
