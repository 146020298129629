import React from 'react';

import { Navigation } from './navigation';

function App() {
  return (
    <div className="App h-full">
      <Navigation />
    </div>
  );
}

export default App;
