import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../api/api';
import { setPayloadInfo } from '../slices/global-store.slice';
import { AppDispatch } from '../store/store';
import { IContent, IPriceItem } from '../store/types';

export const getAllPriceList = createAsyncThunk<{ data: IPriceItem[] }, void>(
  'price-list/get',
  async () => {
    const { data } = await api.priceList.getAllPriceList();
    if (!data) throw 'error';

    return data;
  },
);

export const getPriceContent = createAsyncThunk<{ heading: IContent; description: IContent }, void>(
  'price/content/get',
  async () => {
    const { data } = await api.priceList.getPriceContent();
    if (!data) throw 'error';

    return data.data;
  },
);

export const updatePriceList = createAsyncThunk<
  void,
  { data: IPriceItem },
  { dispatch: AppDispatch }
>('update/price-list/post', async ({ data }, { dispatch }) => {
  const res = await api.priceList.updatePriceList(data);

  if (res?.response?.status > 299) throw 'error';
  dispatch(
    setPayloadInfo({
      detail: 'Price has been saved',
      life: 3000,
      severity: 'success',
      summary: 'Success',
    }),
  );

  dispatch(getAllPriceList());
});

export const updatePriceContentSection = createAsyncThunk<
  void,
  { data: IContent },
  { dispatch: AppDispatch }
>('update/price-content/post', async ({ data }, { dispatch }) => {
  const res = await api.priceList.updatePriceContentSection(data);

  if (res?.response?.status > 299) throw 'error';
  dispatch(
    setPayloadInfo({
      detail: 'Price has been saved',
      life: 3000,
      severity: 'success',
      summary: 'Success',
    }),
  );

  dispatch(getPriceContent());
});
