import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../api/api';
import { AppDispatch } from '../store/store';
import { IFaq } from '../store/types';

export const getAllFaq = createAsyncThunk<{ data: IFaq[] }, void>('faq/get', async () => {
  const { data } = await api.faq.getAllFaq();
  if (!data) throw 'error';

  return data;
});

export const deleteFaq = createAsyncThunk<void, { id: number }, { dispatch: AppDispatch }>(
  'delete-faq/post',
  async ({ id }, { dispatch }) => {
    await api.faq.deleteCar({ id });

    dispatch(getAllFaq());
  },
);

export const updateFaq = createAsyncThunk<void, { data: IFaq }, { dispatch: AppDispatch }>(
  'update-faq/post',
  async ({ data }, { dispatch }) => {
    await api.faq.updateFaq(data);

    dispatch(getAllFaq());
  },
);

export const createFaq = createAsyncThunk<void, { data: Partial<IFaq> }, { dispatch: AppDispatch }>(
  'create-faq/post',
  async ({ data }, { dispatch }) => {
    await api.faq.createFaq(data);

    dispatch(getAllFaq());
  },
);
