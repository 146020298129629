export const linksList = [
  {
    items: [
      {
        label: 'Главный баннер',
        url: '/main/banner',
      },
      {
        label: 'О нас',
        url: '/main/about-us',
      },
      {
        label: 'Отзывы',
        url: '/main/reviews',
      },
      {
        label: 'Отзывы контент',
        url: '/main/testimonials',
      },
      {
        label: 'Видео блог',
        url: '/main/video-blog',
      },
      {
        label: 'Наши Преимущества',
        url: '/main/our-benefits',
      },
      {
        label: 'Машины контент',
        url: '/main/cars',
      },
    ],
    label: 'Главная страницы',
  },
  {
    items: [
      {
        label: 'Контент страницы блог',
        url: '/blog/blogs/content',
      },
      {
        label: 'Список блогов',
        url: '/blog/blog-list',
      },
      {
        label: 'Список тегов',
        url: '/blog/tags-list',
      },
      {
        label: 'Создать блог',
        url: '/blog/create-redact-blog',
      },
    ],
    label: 'Страница Блога',
  },
  {
    items: [
      {
        label: 'Список сервисов',
        url: '/services/services-list',
      },
      {
        label: 'Создать Сервис',
        url: '/services/create-redact-service',
      },
    ],
    label: 'Страница сервисов',
  },
  {
    items: [
      {
        label: 'Контент страницы машин',
        url: '/car/cars/content',
      },
      {
        label: 'Список машин',
        url: '/car/car-list',
      },
      {
        label: 'Создать машину',
        url: '/car/create-redact-car',
      },
    ],
    label: 'Страница машин',
  },
  {
    items: [
      {
        label: 'Что проверяем в машине',
        url: '/form/car-part/content',
      },
      {
        label: 'Контент страницы с формой',
        url: '/form/content',
      },
    ],
    label: 'Страница формы',
  },
  {
    items: [
      {
        label: 'Список FAQ',
        url: '/faq/faq-list',
      },
      {
        label: 'Создать Вопрос Ответ',
        url: '/faq/create-redact',
      },
    ],
    label: 'FAQ',
  },
  {
    items: [
      {
        label: 'Список Прайсов',
        url: '/price/price-list',
      },
    ],
    label: 'Прайс',
  },
  {
    items: [
      {
        label: 'SEO Страниц',
        url: '/pages/pages-ceo',
      },
    ],
    label: 'SEO',
  },
  {
    items: [
      {
        label: 'instagram',
        url: '/settings/social-instagram',
      },
      {
        label: 'facebook',
        url: '/settings/social-facebook',
      },
      {
        label: 'youtube',
        url: '/settings/social-youtube',
      },
      {
        label: 'Email',
        url: '/settings/email',
      },
      {
        label: 'Телефон',
        url: '/settings/phone',
      },
      {
        label: 'Whatsapp',
        url: '/settings/whatsapp',
      },
    ],
    label: 'Глобальные настройки',
  },
];

export const languages = [
  { code: 'en', name: 'English' },
  { code: 'uk', name: 'Ukraine' },
  { code: 'pl', name: 'Poland' },
  { code: 'ru', name: 'Russian' },
];
