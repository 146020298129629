import React, { useState } from 'react';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { useNavigate } from 'react-router-dom';

import { loginWithApi } from '../../actions/auth.action';
import { useAppDispatch } from '../../store/store';

export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loginData, setLogin] = useState<IState>({
    login: '',
    password: '',
  });

  const [error, setError] = useState('');

  const isButtonDisabled = !loginData.login.length && !(loginData.password.length >= 5);

  const handleChange = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!loginData.login.length) return setError('Логин или пароль неправильные');
    if (loginData.password.length <= 5) return setError('Логин или пароль неправильные');
    const token = await dispatch(loginWithApi(loginData)).unwrap();
    if (!token?.length) return setError('Логин или пароль неправильные');
    navigate('/main/banner');
    location.reload();
  };

  return (
    <div className="flex align-items-center justify-content-center h-screen">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <form onSubmit={handleChange}>
          <label htmlFor="email" className="block text-900 font-medium mb-2">
            Логин
          </label>
          <InputText
            id="login"
            type="text"
            placeholder="Login"
            className="w-full mb-3"
            onChange={({ target }) => setLogin({ ...loginData, login: target.value })}
          />

          <label htmlFor="password" className="block text-900 font-medium mb-2">
            Пароль
          </label>
          <InputText
            id="password"
            type="password"
            placeholder="Password"
            className="w-full mb-3"
            onChange={({ target }) => setLogin({ ...loginData, password: target.value })}
          />

          {!!error.length && <Message severity="error" text={error} className={'mb-2 w-full'} />}

          <Button
            label="Войти"
            icon="pi pi-user"
            className="w-full text-black bg-[#ffd54f]"
            type={'submit'}
            disabled={isButtonDisabled}
          />
        </form>
      </div>
    </div>
  );
};

interface IState {
  login: string;
  password: string;
}
