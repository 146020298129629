import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../api/api';
import { setPayloadInfo } from '../slices/global-store.slice';
import { AppDispatch } from '../store/store';
import { IDefaultStackSection } from '../store/types';

export const getContentFormParts = createAsyncThunk<
  { heading: IDefaultStackSection; what_checked: IDefaultStackSection },
  void
>('form/get', async () => {
  const { data } = await api.form.getContentForm();
  if (!data) throw 'error';
  return data.data;
});

export const updateFormContentAction = createAsyncThunk<
  void,
  { section: IDefaultStackSection },
  { dispatch: AppDispatch }
>('form-content/update', async ({ section }, { dispatch }) => {
  const data = await api.form.updateContentForm(section);
  if (data?.response?.status > 299) throw 'error';
  dispatch(
    setPayloadInfo({
      detail: 'Section has been saved',
      life: 3000,
      severity: 'success',
      summary: 'Success',
    }),
  );

  await dispatch(getContentFormParts());
});
