import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAllSettings } from '../actions/settings.action';
import { ISettings, IStore } from '../store/types';

const initialState = {
  list: {
    email: '',
    phone: '',
    social_facebook: '',
    social_instagram: '',
    social_whatsapp: '',
    social_youtube: '',
  },
} as IStore['settings'];

export const settingsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getAllSettings.fulfilled, (state, action: PayloadAction<ISettings>) => {
      state.list = action.payload;
    });
  },
  initialState,
  name: 'setting',
  reducers: {
    updateEmail: (state, action: PayloadAction<string>) => {
      state.list.email = action.payload;
    },
    updatePhone: (state, action: PayloadAction<string>) => {
      state.list.phone = action.payload;
    },
    updateSocialFacebook: (state, action: PayloadAction<string>) => {
      state.list.social_facebook = action.payload;
    },
    updateSocialInstagram: (state, action: PayloadAction<string>) => {
      state.list.social_instagram = action.payload;
    },
    updateSocialWhatsap: (state, action: PayloadAction<string>) => {
      state.list.social_whatsapp = action.payload;
    },
    updateSocialYoutube: (state, action: PayloadAction<string>) => {
      state.list.social_youtube = action.payload;
    },
  },
});

export const {
  updateEmail,
  updatePhone,
  updateSocialFacebook,
  updateSocialInstagram,
  updateSocialYoutube,
  updateSocialWhatsap,
} = settingsSlice.actions;
