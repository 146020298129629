import React, { memo } from 'react';

import { Button } from 'primereact/button';
import { Editor, EditorTextChangeEvent } from 'primereact/editor';
import { FileUploadUploadEvent } from 'primereact/fileupload';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';

import { createCar, updateCar } from '../../actions/car.action';
import { FileUploadComponent } from '../../components/car/file-upload.component';
import { ImageGalleriaComponent } from '../../components/car/image-galleria.component';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { EditorRedactorComponent } from '../../components/core-components/templates/text-redactors/editor-redactor.component';
import {
  deleteImageFromGallery,
  updateCapacity,
  updateCarName,
  updateCertifications,
  updateCheckedText,
  updateDescription,
  updateEngine,
  updateEngineType,
  updateGallery,
  updateHeader,
  updateMileage,
  updatePrice,
  updateTransmission,
  updateVerdictText,
  updateYear,
} from '../../slices/car.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { ICurrentCarGallery } from '../../store/types';

export const CreateRedactCarPage = memo(() => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const { state }: { state?: { id: number } } = useLocation();
  const { currentCar } = useAppSelector(({ car }) => car);

  const handleRedirectToList = () => {
    navigation('/car/car-list');
  };

  const handleSave = () => {
    if (state) {
      dispatch(updateCar(currentCar));
      handleRedirectToList();
      return;
    }
    dispatch(createCar(currentCar));
    handleRedirectToList();
  };

  const onUpload = (e: FileUploadUploadEvent) => {
    const imageData: ICurrentCarGallery[] = JSON.parse(e.xhr.response);
    dispatch(updateGallery(imageData));
  };

  const handleDeleteImage = (v: string) => {
    if (!currentCar.gallery?.length) return;
    const data = currentCar.gallery.filter((item) => item.image_path !== v);
    dispatch(deleteImageFromGallery(data));
  };

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-code-block" aria-label="Code"></button>
      </span>
    );
  };

  const header = renderHeader();

  return (
    <ContainerTemplate>
      {currentCar.gallery?.length ? (
        <ImageGalleriaComponent
          gallery={currentCar.gallery}
          handleDeleteImage={handleDeleteImage}
        />
      ) : null}

      <FileUploadComponent url={'cars/upload_gallery'} onUpload={onUpload} />

      <div className={'grid grid-cols-12 gap-4'}>
        <div className={'col-span-4'}>
          <div className="flex flex-column mb-10">
            <div className={'flex items-start'}>
              <label htmlFor="car_name">Модель машины</label>
              <p className="text-red-600">*</p>
            </div>
            <InputText
              id="car_name"
              aria-describedby="car_name-help"
              value={currentCar.car_name ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(updateCarName(e.target.value))
              }
            />
          </div>
        </div>
        <div className={'col-span-2'}>
          <div className="flex flex-column mb-10">
            <label htmlFor="price">Цена в злотых</label>
            <InputNumber
              inputId="price"
              value={currentCar.price_pln ?? ''}
              useGrouping={false}
              onValueChange={(e: InputNumberValueChangeEvent) => {
                if (!e.value) return;
                dispatch(updatePrice(e.value));
              }}
            />
          </div>
        </div>
        <div className={'col-span-2'}>
          <div className="flex flex-column mb-10">
            <div className={'flex items-start'}>
              <label htmlFor="year">Год</label>
            </div>
            <InputNumber
              inputId="year"
              value={currentCar.year ?? ''}
              useGrouping={false}
              onValueChange={(e: InputNumberValueChangeEvent) => {
                if (!e.value) return;
                dispatch(updateYear(e.value));
              }}
            />
          </div>
        </div>
        <div className={'col-span-2'}>
          <div className="flex flex-column mb-10">
            <label htmlFor="mileage">Пробег</label>
            <InputNumber
              inputId="mileage"
              value={currentCar.mileage ?? ''}
              useGrouping={false}
              onValueChange={(e: InputNumberValueChangeEvent) => {
                if (!e.value) return;
                dispatch(updateMileage(e.value));
              }}
            />
          </div>
        </div>
        <div className={'col-span-2'}>
          <div className="flex flex-column mb-10">
            <label htmlFor="capacity">Мощность</label>
            <InputNumber
              inputId="capacity"
              value={currentCar.capacity ?? ''}
              useGrouping={false}
              onValueChange={(e: InputNumberValueChangeEvent) => {
                if (!e.value) return;
                dispatch(updateCapacity(e.value));
              }}
            />
          </div>
        </div>
        <div className={'col-span-2'}>
          <div className="flex flex-column mb-10">
            <label htmlFor="engine">Объем двигателя</label>
            <InputNumber
              inputId="engine"
              value={currentCar.engine ?? ''}
              useGrouping={false}
              onValueChange={(e: InputNumberValueChangeEvent) => {
                if (!e.value) return;
                dispatch(updateEngine(e.value));
              }}
            />
          </div>
        </div>
        <div className={'col-span-2'}>
          <div className="flex flex-column mb-10">
            <label htmlFor="engine_type">Тип топлива</label>
            <InputText
              id="engine_type"
              aria-describedby="engine_type-help"
              value={currentCar.engine_type ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(updateEngineType(e.target.value))
              }
            />
          </div>
        </div>
        <div className={'col-span-2'}>
          <div className="flex flex-column mb-10">
            <label htmlFor="transmission">Трансмиссия</label>
            <InputText
              id="transmission"
              aria-describedby="transmission-help"
              value={currentCar.transmission ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(updateTransmission(e.target.value))
              }
            />
          </div>
        </div>

        <div className={'col-span-3'}>
          <div className="flex flex-column mb-10">
            <label htmlFor="certifications">Сертификация</label>
            <InputText
              id="certifications"
              aria-describedby="certifications-help"
              value={currentCar.certifications ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(updateCertifications(e.target.value))
              }
            />
          </div>
        </div>
        <div className={'col-span-3'}>
          <div className="flex flex-column mb-10">
            <label htmlFor="verdict_text">Итог по машине</label>
            <InputText
              id="verdict_text"
              aria-describedby="verdict_text-help"
              value={currentCar.verdict_text ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(updateVerdictText(e.target.value))
              }
            />
          </div>
        </div>
      </div>
      <div className={'mb-10'}>
        <EditorRedactorComponent
          title={'Что было проверенно в машине'}
          value={currentCar.checked_text ?? ''}
          id={'checked_text'}
          onChange={(v) => dispatch(updateCheckedText(v))}
        />
      </div>
      <EditorRedactorComponent
        title={'О машине'}
        value={currentCar.description ?? ''}
        id={'description'}
        onChange={(v) => dispatch(updateDescription(v))}
        isReq={true}
      />
      {/*updateHeader*/}
      <div className={'flex flex-col w-full mt-10'}>
        <div className={'flex items-start'}>
          <label htmlFor={'blog_ceo'}>SEO Машины</label>
        </div>
        <Editor
          id={'car_ceo'}
          value={currentCar.header ?? ''}
          onTextChange={(e: EditorTextChangeEvent) =>
            dispatch(updateHeader(e.htmlValue ? e.htmlValue : ''))
          }
          style={{ height: '320px' }}
          headerTemplate={header}
        />
      </div>
      <Button
        label="Save"
        icon="pi pi-check"
        className={'w-full mt-10'}
        onClick={handleSave}
      />
    </ContainerTemplate>
  );
});
