import { createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';

import { api } from '../api/api';
import { Slugify } from '../helpers';
import { uploadMoreBlogs } from '../slices/blog.slice';
import { setPayloadInfo } from '../slices/global-store.slice';
import { AppDispatch } from '../store/store';
import { IBlog, IContentTitleDescription, ICurrentBlog, IStore, ITag } from '../store/types';

export const getAllTags = createAsyncThunk<ITag[] | [], void>('tags/get', async () => {
  const { data } = await api.blog.getAllTags();
  if (!data) throw 'error';
  return data.data;
});

export const getContentBlog = createAsyncThunk<IContentTitleDescription, void>(
  'content-blog/get',
  async () => {
    const { data } = await api.blog.getContentBlog();
    if (!data) throw 'error';
    return data.data.heading;
  },
);

export const getAllBlogs = createAsyncThunk<
  { data: IBlog[] | []; total_count: number },
  void,
  { state: IStore }
>('blogs/get', async (_, { getState }) => {
  const { limit } = getState().blog.page;
  const { data } = await api.blog.getAllBlogs(limit);
  if (!data) throw 'error';
  return data;
});

export const getMoreBlogs = createAsyncThunk<void, void, { state: IStore; dispatch: AppDispatch }>(
  'more-blogs/get',
  async (_, { getState, dispatch }) => {
    const { limit, count } = getState().blog.page;
    const { total_count, list } = getState().blog.blog;

    const countOfMoreBlogs = total_count - list.length >= limit ? limit + count : total_count;

    dispatch(uploadMoreBlogs(countOfMoreBlogs));
    dispatch(getAllBlogs());
  },
);

export const getBlog = createAsyncThunk<ICurrentBlog, { id: number }>(
  'one-blog/get',
  async ({ id }) => {
    const { data } = await api.blog.getBlog(id);
    if (!data) throw 'error';
    return data.data;
  },
);

export const createBlog = createAsyncThunk<
  void,
  { imagePath: string; tags: number[] | null },
  { dispatch: AppDispatch; state: IStore }
>('create-blog/post', async ({ imagePath, tags }, { dispatch, getState }) => {
  const currentBlog = getState().blog.currentBlog;

  await api.blog.createBlog({
    ...currentBlog,
    image: imagePath,
    published_at: format(new Date(currentBlog.published_at.toString()), 'yyyy-MM-dd h:mm:ss'),
    slug: Slugify(currentBlog.title),
    tags,
  });

  dispatch(getAllBlogs());
});

export const updateBlog = createAsyncThunk<
  void,
  { image: string | null; id: number; tags: number[] | null },
  { dispatch: AppDispatch; state: IStore }
>('create-blog/post', async ({ image, id, tags }, { dispatch, getState }) => {
  const currentBlog = getState().blog.currentBlog;

  await api.blog.updateBlog(
    image
      ? {
          ...currentBlog,
          id,
          image,
          tags,
        }
      : {
          author: currentBlog.author,
          description: currentBlog.description,
          id,
          published_at: currentBlog.published_at,
          slug: currentBlog.slug,
          tags,
          title: currentBlog.title,
        },
  );
  console.log(
    'asdasd',
    image
      ? {
          ...currentBlog,
          id,
          image,
          tags,
        }
      : {
          author: currentBlog.author,
          description: currentBlog.description,
          id,
          published_at: currentBlog.published_at,
          slug: currentBlog.slug,
          tags,
          title: currentBlog.title,
        },
  );
  dispatch(getAllBlogs());
});

export const deleteBlog = createAsyncThunk<void, { id: number }, { dispatch: AppDispatch }>(
  'delete-blog/post',
  async ({ id }, { dispatch }) => {
    await api.blog.deleteBlog({ id });

    dispatch(getAllBlogs());
  },
);

export const deleteTag = createAsyncThunk<void, { id: number }, { dispatch: AppDispatch }>(
  'delete-tag/post',
  async ({ id }, { dispatch }) => {
    await api.blog.deleteTag({ id });

    dispatch(getAllTags());
  },
);

export const addTag = createAsyncThunk<void, { name: string }, { dispatch: AppDispatch }>(
  'add-tag/post',
  async ({ name }, { dispatch }) => {
    await api.blog.addTag({ name, slug: Slugify(name) });

    dispatch(getAllTags());
  },
);

export const updateSection = createAsyncThunk<
  void,
  { section: IContentTitleDescription },
  { dispatch: AppDispatch }
>('update/section', async ({ section }, { dispatch }) => {
  const data = await api.home.updateSection(section);
  if (data?.response?.status > 299) throw 'error';
  dispatch(
    setPayloadInfo({
      detail: 'Section has been saved',
      life: 3000,
      severity: 'success',
      summary: 'Success',
    }),
  );

  await dispatch(getContentBlog());
});
