import { FC } from 'react';

import { Checkbox } from 'primereact/checkbox';

import { ITag } from '../../store/types';

export const TagCheckboxItemComponent: FC<IProps> = ({ item, isChecked, toggleCheck }) => {
  return (
    <div className="flex align-items-center mr-2">
      <Checkbox
        inputId={item.slug}
        name="tag"
        value={item}
        onChange={() => toggleCheck(item)}
        checked={isChecked}
      />
      <label htmlFor={item.slug} className="ml-2">
        {item.tag}
      </label>
    </div>
  );
};

interface IProps {
  item: ITag;
  isChecked: boolean;
  toggleCheck: (tag: ITag) => void;
}
