import React from 'react';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useLocation, useNavigate } from 'react-router-dom';

import { createFaq, updateFaq } from '../../actions/faq.action';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { clearCurrent, updateAnswer, updateQuestion } from '../../slices/faq.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';

export const CreateRedactFaqPage = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const { state }: { state?: { id: number } } = useLocation();

  const { current } = useAppSelector((state) => state.faqPage);
  const { question, answer } = current;

  const handleRedirectToList = () => {
    dispatch(clearCurrent());
    navigation('/faq/faq-list');
  };

  const handleSave = () => {
    if (state) {
      dispatch(updateFaq({ data: current }));
      handleRedirectToList();
      return;
    }
    dispatch(createFaq({ data: current }));
    handleRedirectToList();
  };

  return (
    <ContainerTemplate>
      <div>
        <div className="flex flex-column col-span-9">
          <div className={'flex items-start'}>
            <label htmlFor="year">Вопрос</label>
            <p className="text-red-600">*</p>
          </div>
          <InputText
            id="anser"
            aria-describedby="answer-help"
            value={question ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(updateQuestion(e.target.value))
            }
          />
        </div>
        <div className="flex flex-column col-span-9 mt-10">
          <div className={'flex items-start'}>
            <label htmlFor="year">Ответ</label>
            <p className="text-red-600">*</p>
          </div>
          <InputTextarea
            id="question"
            aria-describedby="question-help"
            value={answer ?? ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              dispatch(updateAnswer(e.target.value))
            }
          />
        </div>
      </div>
      <Button
        label="Сохранить"
        icon="pi pi-check"
        className={'w-full mt-10'}
        onClick={handleSave}
      />
    </ContainerTemplate>
  );
};
