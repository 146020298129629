import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../api/api';
import { AppDispatch } from '../store/store';
import { IPageSeo } from '../store/types';

import { handlePayloadInfoAction } from './globall.action';

export const updatePageAction = createAsyncThunk<
  void,
  Partial<IPageSeo>,
  { dispatch: AppDispatch }
>('pages/update', async (data, { dispatch }) => {
  console.log('datadata', data);
  const { status } = await api.pages.updatePage(data);

  dispatch(handlePayloadInfoAction({ statusCode: status }));

  await dispatch(getAllPages());
});

export const getAllPages = createAsyncThunk<{ data: IPageSeo[] }, void>('pages/get', async () => {
  const { data } = await api.pages.getAllPages();
  if (!data) throw 'error';
  return data;
});
