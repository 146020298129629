import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAllPages } from '../actions/pages.action';
import { IPageSeo, IStore } from '../store/types';

const initialState = {
  data: [],
} as IStore['pages'];

export const pagesSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getAllPages.fulfilled, (state, action: PayloadAction<{ data: IPageSeo[] }>) => {
      state.data = action.payload.data;
    });
  },
  initialState,
  name: 'pages',
  reducers: {
    updatePageHeader: (state, action: PayloadAction<{ id: number; value: string }>) => {
      state.data = state.data.map((item) =>
        item.id === action.payload.id ? { ...item, header: action.payload.value } : item,
      );
    },
  },
});

export const { updatePageHeader } = pagesSlice.actions;
