import { createAsyncThunk } from '@reduxjs/toolkit';

import { api, ILoginData } from '../api/api';

export const loginWithApi = createAsyncThunk<string | null, ILoginData>(
  'auth/login',
  async (loginData) => {
    const data = await api.login.login(loginData);
    if (!data?.data?.token) return null;
    localStorage.setItem('token', data.data.token);
    return data.data.token;
  },
);

export const logoutAction = createAsyncThunk<void, void>('auth/logout', async () => {
  localStorage.removeItem('token');
  location.reload();
});
