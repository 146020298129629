import { Button } from 'primereact/button';

import { getMoreServices } from '../../actions/services.action';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { ServicesListComponent } from '../../components/services/services-list.component';
import { useAppDispatch, useAppSelector } from '../../store/store';

export const ServicesPage = () => {
  const dispatch = useAppDispatch();

  const { list, total_count } = useAppSelector(({ services }) => services.services);

  const isMoreBlogs = total_count > list.length;

  const handleUploadMore = async () => {
    await dispatch(getMoreServices());
  };

  return (
    <ContainerTemplate>
      <h1 className={'text-2xl text-center mb-10'}>Страница сервисов</h1>

      <ServicesListComponent list={list} />

      <div className={'w-full flex items-center justify-center mt-10'}>
        {isMoreBlogs && (
          <Button
            label="Загрузить больше"
            className="p-button-warning"
            onClick={handleUploadMore}
          />
        )}
      </div>
    </ContainerTemplate>
  );
};
