import {
  IContentTitleDescription,
  IBlogForSend,
  ICar,
  ICarParts,
  IDefaultStackSection,
  IService,
  IFaq,
  IPriceItem,
  IContent,
  IPageSeo,
} from '../store/types';

import { instance, instanceAuthorization } from './config';
import { handleError } from './handler';

export const api = {
  blog: {
    addTag(data: { name: string; slug: string }) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'blog/tags/add',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    createBlog(data: IBlogForSend) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'blog/create_post',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    deleteBlog(data: { id: number }) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'blog/delete_post',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    deleteTag(data: { id: number }) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'blog/tags/delete',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    getAllBlogs(limit: number) {
      return instanceAuthorization({
        method: 'get',
        url: `blog/get_posts?limit=${limit}`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    getAllTags() {
      return instanceAuthorization({
        method: 'get',
        url: `blog/tags/get`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    getBlog(id: number) {
      return instanceAuthorization({
        method: 'get',
        url: `blog/get_post/${id}`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },

    getContentBlog() {
      return instanceAuthorization({
        method: 'get',
        url: `pages/get_blog_sections`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    updateBlog(data: Partial<IBlogForSend>) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'blog/update_post',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
  },

  carParts: {
    getAllCarsPart() {
      return instanceAuthorization({
        method: 'get',
        url: `car_parts/all`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    getContentCarParts() {
      return instanceAuthorization({
        method: 'get',
        url: ``,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    updateCarPart(data: ICarParts) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'car_parts/update',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
  },

  cars: {
    createCar(data: ICar) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'cars/add_car',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    deleteCar(data: { id: number }) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'cars/delete_car',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    getAllCars(limit: number) {
      return instanceAuthorization({
        method: 'get',
        url: `cars/get_cars?limit=${limit}`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    getCar(id: number) {
      return instanceAuthorization({
        method: 'get',
        url: `cars/get_car/${id}`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    getContentCars() {
      return instanceAuthorization({
        method: 'get',
        url: `pages/get_cars_sections`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    updateCar(data: ICar) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'cars/update_car',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
  },

  faq: {
    createFaq(data: Partial<IFaq>) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'faq/add',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    deleteCar(data: { id: number }) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'faq/delete',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    getAllFaq() {
      return instanceAuthorization({
        method: 'get',
        url: `faq/all`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    updateFaq(data: IFaq) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'faq/update',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
  },

  form: {
    getContentForm() {
      return instanceAuthorization({
        method: 'get',
        url: `pages/get_contact_sections`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    updateContentForm(data: IDefaultStackSection | IContentTitleDescription) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'pages/update_section',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
  },

  home: {
    getAllHomeContent() {
      return instanceAuthorization({
        method: 'get',
        url: 'pages/get_sections',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    updateSection(data: IDefaultStackSection | IContentTitleDescription) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'pages/update_section',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
  },

  login: {
    login(data: ILoginData) {
      return instance({
        data,
        method: 'post',
        url: 'login',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
  },

  pages: {
    getAllPages() {
      return instanceAuthorization({
        method: 'get',
        url: 'pages/get',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    updatePage(data: Partial<IPageSeo>) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'pages/update_page',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
  },

  priceList: {
    getAllPriceList() {
      return instanceAuthorization({
        method: 'get',
        url: `price_list/all`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    getPriceContent() {
      return instanceAuthorization({
        method: 'get',
        url: `pages/get_pricing_sections`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    updatePriceContentSection(data: IContent) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'pages/update_section',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    updatePriceList(data: IPriceItem) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'price_list/update',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
  },

  reviews: {
    getAllReviews() {
      return instanceAuthorization({
        method: 'get',
        url: 'testimonials/all',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    removeReview(id: number) {
      return instanceAuthorization({
        method: 'get',
        url: `testimonials/delete/${id}`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
  },

  services: {
    createService(data: IService) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'services/add_service',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    deleteService(data: { id: number }) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'services/delete_service',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    getAllServices(limit: number) {
      return instanceAuthorization({
        method: 'get',
        url: `services/get_services?limit=${limit}`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    getAllServicesFavorites() {
      return instanceAuthorization({
        method: 'get',
        url: `services/get_favorites`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    getService(id: number) {
      return instanceAuthorization({
        method: 'get',
        url: `services/get_service/${id}`,
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    makeCheckService(data: { ids: number[] | [] }) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'services/make_favorites',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    updateService(data: IService) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'services/update_service',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
  },

  settings: {
    getAllSettings() {
      return instanceAuthorization({
        method: 'get',
        url: 'settings/all',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
    updateSettings(data: ISettingData) {
      return instanceAuthorization({
        data,
        method: 'post',
        url: 'settings/update',
      }).catch((error) => {
        handleError(error.response.status);
        return error;
      });
    },
  },
};

export interface ILoginData {
  login: string;
  password: string;
}

export interface ISettingData {
  key: string;
  value: string;
}
