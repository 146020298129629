import { useState } from 'react';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';

import { deleteFaq } from '../../actions/faq.action';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { FaqItemComponent } from '../../components/faq/faq-item.component';
import { addCurrentFaq, clearCurrent } from '../../slices/faq.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';

const initialValue = {
  id: 0,
  isVisible: false,
};

export const FaqPage = () => {
  const dispatch = useAppDispatch();

  const navigation = useNavigate();

  const { list } = useAppSelector((state) => state.faqPage);

  const [visibleModal, setVisibleModal] = useState<{ isVisible: boolean; id: number }>(
    initialValue,
  );

  const openModal = (id: number) => {
    setVisibleModal({ id, isVisible: true });
  };

  const deleteItem = () => {
    dispatch(deleteFaq({ id: visibleModal.id }));
    setVisibleModal(initialValue);
  };

  const handleRedirect = async (id: number) => {
    dispatch(clearCurrent());
    const foundItem = list.find((item) => item.id === id);
    if (!foundItem) return;
    dispatch(addCurrentFaq(foundItem));
    navigation('/faq/create-redact', { state: { id } });
  };

  const onCreate = () => {
    dispatch(clearCurrent());

    navigation('/faq/create-redact');
  };

  return (
    <ContainerTemplate>
      <div className={'pb-10'}>
        <h1 className={'text-2xl text-center mb-10'}>Страница FAQ</h1>
        <div className={'grid grid-cols-4 gap-4'}>
          {list.map((item, key) => (
            <FaqItemComponent
              item={item}
              key={key}
              openWarningModal={openModal}
              onClick={handleRedirect}
            />
          ))}
        </div>
        <Dialog
          header="Вы действительно хотите удалить блог?"
          visible={visibleModal.isVisible}
          style={{ width: '50vw' }}
          onHide={() => setVisibleModal({ ...visibleModal, isVisible: false })}
        >
          <Button label="Удалить" severity="success" onClick={deleteItem} className={'w-full'} />
        </Dialog>
        <Button
          label="Добавить вопрос ответ"
          icon="pi pi-file-edit"
          onClick={onCreate}
          className={'fixed left-10 bottom-10'}
        />
      </div>
    </ContainerTemplate>
  );
};
