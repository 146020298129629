import React, { FC } from 'react';

import { Editor, EditorTextChangeEvent } from 'primereact/editor';

export const EditorRedactorComponent: FC<IProps> = ({
  title,
  value,
  onChange,
  id,
  isReq = false,
}) => {
  return (
    <div className={'flex flex-col w-full'}>
      <div className={'flex items-start'}>
        <label htmlFor={id}>{title}</label>
        {isReq ? <p className="text-red-600">*</p> : null}
      </div>
      <Editor
        id={id}
        value={value}
        onTextChange={(e: EditorTextChangeEvent) => onChange(e.htmlValue ? e.htmlValue : '')}
        style={{ height: '320px' }}
      />
    </div>
  );
};

interface IProps {
  title: string;
  value: string;
  id: string;
  onChange: (v: string) => void;
  isReq?: boolean;
}
