import { FC } from 'react';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputSwitch } from 'primereact/inputswitch';

import { addCheckService } from '../../actions/services.action';
import { updateServicesFavorites } from '../../slices/services.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { IService } from '../../store/types';

export const ServiceItemComponent: FC<IProps> = ({ content, onClick, openWarningModal }) => {
  const dispatch = useAppDispatch();

  const { ids } = useAppSelector(({ services }) => services.favorites);

  const isChecked = ids.some((itemFavorite) => itemFavorite === content.id);

  const isMakeMoreIsChecked = !isChecked && ids.length >= 3;

  const header = content.image ? (
    <img alt="Card" className={'h-[380px] object-cover'} src={content.image} />
  ) : (
    <div className={'h-[380px]'} />
  );

  const handleCheck = async () => {
    if (isMakeMoreIsChecked) return;
    const favoriteList = isChecked
      ? ids.filter((item) => item !== content.id)
      : [...ids, content.id];
    dispatch(updateServicesFavorites(favoriteList));
    await dispatch(addCheckService({ ids: favoriteList }));
  };
  const getTitleContent = () => {
    return (
      <>
        <div className={'grid grid-cols-12 gap-2 items-center'}>
          <p className={'text-xs col-span-10'}>Отображать на главной странице(макс 3)</p>
          <InputSwitch
            className={`${isMakeMoreIsChecked ? 'p-invalid' : ''} col-span-2`}
            checked={isChecked}
            onChange={handleCheck}
            disabled={isMakeMoreIsChecked}
          />
        </div>
        <div className={'gap-2'}>{content.title}</div>
      </>
    );
  };

  const footer = (
    <div className="flex flex-wrap justify-content-end gap-2">
      <Button
        label="Редактировать"
        icon="pi pi-file-edit"
        onClick={() => onClick(content.id ? content.id : 0)}
        className={'w-full'}
      />
      <Button
        label="Удалить"
        severity="danger"
        icon={'pi pi-times'}
        onClick={() => openWarningModal(content.id ? content.id : 0)}
        className={'w-full'}
      />
    </div>
  );

  return (
    <div className="card flex justify-content-center justify-items-end">
      <Card
        title={getTitleContent}
        footer={footer}
        header={header}
        className="md:w-25rem flex flex-col justify-between"
      />
    </div>
  );
};

interface IProps {
  content: IService;
  onClick: (id: number) => void;
  openWarningModal: (id: number) => void;
}
