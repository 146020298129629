import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../api/api';
import { setPayloadInfo } from '../slices/global-store.slice';
import { AppDispatch } from '../store/store';
import { IDefaultStackSection, IReviewItem, ISections } from '../store/types';

export const removeReviewAction = createAsyncThunk<void, { id: number }, { dispatch: AppDispatch }>(
  'reviews/remove',
  async ({ id }, { dispatch }) => {
    await api.reviews.removeReview(id);

    await dispatch(getAllReviews());
  },
);

export const getAllReviews = createAsyncThunk<IReviewItem[] | [], void>('reviews/get', async () => {
  const { data } = await api.reviews.getAllReviews();
  if (!data) throw 'error';
  return data.data;
});

export const getAllHomeContent = createAsyncThunk<ISections, void>('home-content/get', async () => {
  const { data } = await api.home.getAllHomeContent();
  if (!data) throw 'error';
  return data.data;
});

export const updateSection = createAsyncThunk<
  void,
  { section: IDefaultStackSection },
  { dispatch: AppDispatch }
>('update/section', async ({ section }, { dispatch }) => {
  const data = await api.home.updateSection(section);
  if (data?.response?.status > 299) throw 'error';
  dispatch(
    setPayloadInfo({
      detail: 'Section has been saved',
      life: 3000,
      severity: 'success',
      summary: 'Success',
    }),
  );

  await dispatch(getAllHomeContent());
});
