import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../api/api';
import { uploadMoreServices } from '../slices/services.slice';
import { AppDispatch } from '../store/store';
import { IService, IStore } from '../store/types';

export const getAllServices = createAsyncThunk<
  { data: IService[] | []; total_count: number },
  void,
  { state: IStore }
>('services/get', async (_, { getState }) => {
  const { limit } = getState().blog.page;
  const { data } = await api.services.getAllServices(limit);
  if (!data) throw 'error';
  return data;
});

export const getAllServicesFavorites = createAsyncThunk<{ ids: number[] | [] }, void>(
  'services/favorites/get',
  async () => {
    const { data } = await api.services.getAllServicesFavorites();

    return data;
  },
);

export const getMoreServices = createAsyncThunk<
  void,
  void,
  { state: IStore; dispatch: AppDispatch }
>('more-services/get', async (_, { getState, dispatch }) => {
  const { limit, count } = getState().services.page;
  const { total_count, list } = getState().services.services;

  const countOfMoreBlogs = total_count - list.length >= limit ? limit + count : total_count;

  dispatch(uploadMoreServices(countOfMoreBlogs));
  dispatch(getAllServices());
});

export const getService = createAsyncThunk<IService, { id: number }>(
  'one-service/get',
  async ({ id }) => {
    const { data } = await api.services.getService(id);
    if (!data) throw 'error';
    return data.data;
  },
);

export const createService = createAsyncThunk<void, IService, { dispatch: AppDispatch }>(
  'create-service/post',
  async (data, { dispatch }) => {
    await api.services.createService(data);

    dispatch(getAllServices());
  },
);

export const updateService = createAsyncThunk<
  void,
  IService,
  { dispatch: AppDispatch; state: IStore }
>('create-service/post', async (data, { dispatch }) => {
  await api.services.updateService(data);
  dispatch(getAllServices());
});

export const deleteService = createAsyncThunk<void, { id: number }, { dispatch: AppDispatch }>(
  'delete-service/post',
  async ({ id }, { dispatch }) => {
    await api.services.deleteService({ id });

    dispatch(getAllServices());
  },
);

export const addCheckService = createAsyncThunk<
  void,
  { ids: number[] | [] },
  { dispatch: AppDispatch }
>('check-service/post', async (data, { dispatch }) => {
  await api.services.makeCheckService(data);

  dispatch(getAllServices());
  dispatch(getAllServicesFavorites());
});
