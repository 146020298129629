import { useEffect, useRef, useState } from 'react';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import {
  FileUpload,
  FileUploadBeforeSendEvent,
  FileUploadUploadEvent,
} from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';

import { updateSection } from '../../actions/main.action';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { updateAboutUs } from '../../slices/main-content.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { ListItemContentType, ListItemType } from "../../store/types";

export const AboutUsPage = () => {
  const dispatch = useAppDispatch();

  const { about } = useAppSelector(({ main }) => main.sections);
  const [image, setImage] = useState<{ image_path: string; image_url: string } | null>(null);

  useEffect(() => {
    if (!about.media) return;
    setImage({ image_path: '', image_url: about.media });
  }, []);

  const token = localStorage.getItem('token');

  const toast = useRef<Toast>(null);

  const handleUpdateAboutUsContent = (key: string, value: string) => {
    dispatch(updateAboutUs({ ...about, [key]: value }));
  };

  const onUpload = async (e: FileUploadUploadEvent) => {
    const imageData: { image_path: string; image_url: string } = JSON.parse(e.xhr.response);
    setImage(imageData);
    if (!toast.current) return;
    toast.current.show({ detail: 'File Uploaded', severity: 'info', summary: 'Success' });
  };

  const onBeforeSend = (event: FileUploadBeforeSendEvent) => {
    event.xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  };

  if (!about) return null;

  const handleSaveAboutUs = () => {
    if (image) {
      dispatch(updateSection({ section: { ...about, media: image.image_path } }));
      return;
    }
    dispatch(updateSection({ section: about }));
  };

  const handleUpdateAboutUsListContent = (
      key: [ListItemType, ListItemContentType],
      value: string,
  ) => {
    dispatch(updateAboutUs({ ...about, [key[0]]: { ...about[key[0]], [key[1]]: value } }))
  }

  return (
    <ContainerTemplate>
      {image ? (
        <div className={'w-full flex items-center justify-center'}>
          <img
            src={image.image_url}
            alt={'about_us_image'}
            className="w-full rounded-2xl h-full min-h-[325px] max-h-[535px] max-w-[635px] object-cover mb-10"
          />
        </div>
      ) : null}

      <div className="card flex justify-content-center mb-10">
        <Toast ref={toast}></Toast>
        <FileUpload
          chooseLabel={'Выбрать'}
          onBeforeSend={onBeforeSend}
          mode="basic"
          name="media"
          url="https://devdata.autorevizor.com.pl/api/admin/pages/upload_media"
          accept="image/*"
          maxFileSize={300000000}
          onUpload={onUpload}
        />
      </div>
      <Card title="Блок о нас заголовок и описание" className={'border-2 border-yellow'}>
        <div className="mt-2">
          <label htmlFor="headline">Заголовок</label>
          <InputTextarea
            className={'w-full'}
            id="headline"
            value={about.title ?? ''}
            onChange={({ target }) => handleUpdateAboutUsContent('title', target.value)}
          />
        </div>
        <div className="mt-2">
          <label htmlFor="description">Описание</label>
          <InputTextarea
            className={'w-full'}
            id="description"
            value={about.description ?? ''}
            onChange={({ target }) => handleUpdateAboutUsContent('description', target.value)}
          />
        </div>
        <div className={'flex justify-end'}>
          <Button
            label="Сохранить"
            icon="pi pi-check"
            className={'mt-2'}
            onClick={handleSaveAboutUs}
          />
        </div>
      </Card>
      <Card title="Общее описание" className={'border-2 border-yellow mt-10'}>
        <div className="mt-2">
          <label htmlFor="headline">Заголовок</label>
          <InputTextarea
              className={'w-full'}
              id="headline"
              value={about.list_item_one?.heading ?? ''}
              onChange={({ target }) =>
                  handleUpdateAboutUsListContent(['list_item_one', 'heading'], target.value)
              }
          />
        </div>
        <div className="mt-2">
          <label htmlFor="description">Описание первый абзац</label>
          <InputTextarea
              className={'w-full min-h-[300px]'}
              id="description"
              value={about.list_item_one?.description ?? ''}
              onChange={({ target }) =>
                  handleUpdateAboutUsListContent(['list_item_one', 'description'], target.value)
              }
          />
        </div>
        <div className="mt-2">
          <label htmlFor="description">Описание второй абзац</label>
          <InputTextarea
              className={'w-full min-h-[300px]'}
              id="description"
              value={about.list_item_two?.description ?? ''}
              onChange={({ target }) =>
                  handleUpdateAboutUsListContent(['list_item_two', 'description'], target.value)
              }
          />
        </div>
        <div className="mt-2">
          <label htmlFor="description">Описание третий абзац</label>
          <InputTextarea
              className={'w-full min-h-[300px]'}
              id="description"
              value={about.list_item_three?.description ?? ''}
              onChange={({ target }) =>
                  handleUpdateAboutUsListContent(['list_item_three', 'description'], target.value)
              }
          />
        </div>
        <div className={'flex justify-end'}>
          <Button
              label="Сохранить"
              icon="pi pi-check"
              className={'mt-2'}
              onClick={handleSaveAboutUs}
          />
        </div>
      </Card>
    </ContainerTemplate>
  );
};
