import { FC } from 'react';

import { Tag } from 'primereact/tag';

import { ITag } from '../../store/types';

export const TagItemComponent: FC<IProps> = ({ item, onRemove }) => {
  return (
    <div className={'mr-4 flex items-stretch'}>
      <Tag severity="warning" value={item.tag} />
      <div
        className={'bg-red cursor-pointer px-1 flex items-center justify-center'}
        onClick={() => onRemove(item.id)}
      >
        <i className="pi pi-times" style={{ color: 'white', fontSize: '0.5rem' }} />
      </div>
    </div>
  );
};

interface IProps {
  item: ITag;
  onRemove: (id: number) => void;
}
