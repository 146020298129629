import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAllBlogs, getAllTags, getBlog, getContentBlog } from '../actions/blog.action';
import { IBlog, IContentTitleDescription, ICurrentBlog, IStore, ITag } from '../store/types';

const initialState = {
  blog: {
    list: [],
    total_count: 0,
  },
  content: {
    description: '',
    title: '',
  },
  currentBlog: {
    author: 'AutoRevizor',
    description: '',
    header: null,
    image: null,
    published_at: new Date().toString(),
    slug: '',
    tags: null,
    title: '',
  },
  page: {
    count: 20,
    limit: 20,
  },
  tags: {
    list: [],
  },
} as IStore['blog'];

export const blogSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      getAllBlogs.fulfilled,
      (state, action: PayloadAction<{ data: IBlog[] | []; total_count: number }>) => {
        if (!action.payload) return;
        state.blog.list = action.payload.data;
        state.blog.total_count = action.payload.total_count;
      },
    );
    builder.addCase(getAllTags.fulfilled, (state, action: PayloadAction<ITag[] | []>) => {
      state.tags.list = action.payload;
    });
    builder.addCase(
      getContentBlog.fulfilled,
      (state, action: PayloadAction<IContentTitleDescription>) => {
        state.content = action.payload;
      },
    );
    builder.addCase(getBlog.fulfilled, (state, action: PayloadAction<ICurrentBlog>) => {
      state.currentBlog = action.payload;
    });
  },
  initialState,
  name: 'blog',
  reducers: {
    clearBlog: (state) => {
      state.currentBlog = initialState.currentBlog;
    },
    setImageToCurrentBlog: (state, action: PayloadAction<string>) => {
      state.currentBlog.image = action.payload;
    },
    updateContentBlog: (state, action: PayloadAction<IContentTitleDescription>) => {
      state.content = action.payload;
    },
    updateDate: (state, action: PayloadAction<string>) => {
      state.currentBlog.published_at = action.payload;
    },
    updateDescription: (state, action: PayloadAction<string>) => {
      state.currentBlog.description = action.payload;
    },
    updateHeader: (state, action: PayloadAction<string>) => {
      state.currentBlog.header = action.payload;
    },
    updateTag: (state, action: PayloadAction<ITag[]>) => {
      state.currentBlog.tags = action.payload;
    },
    updateTitle: (state, action: PayloadAction<string>) => {
      state.currentBlog.title = action.payload;
    },
    uploadMoreBlogs: (state, action: PayloadAction<number>) => {
      state.page.limit = action.payload;
    },
  },
});

export const {
  uploadMoreBlogs,
  clearBlog,
  setImageToCurrentBlog,
  updateDescription,
  updateTitle,
  updateDate,
  updateTag,
  updateContentBlog,
  updateHeader,
} = blogSlice.actions;
