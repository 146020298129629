import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAllCars, getCar, getContentCar } from '../actions/car.action';
import {
  IContentTitleDescription,
  ICar,
  ICurrentCar,
  ICurrentCarGallery,
  IStore,
} from '../store/types';

const initialState = {
  car: {
    list: [],
    total_count: 0,
  },
  content: {
    description: '',
    title: '',
  },
  currentCar: {
    capacity: 0,
    car_name: '',
    certifications: '',
    checked_text: '',
    description: '',
    engine: 0,
    engine_type: '',
    gallery: null,
    header: null,
    image: null,
    mileage: 0,
    price_pln: 0,
    transmission: '',
    verdict_text: '',
    year: 0,
  },
  page: {
    count: 20,
    limit: 20,
  },
} as IStore['car'];

export const carSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      getAllCars.fulfilled,
      (state, action: PayloadAction<{ data: ICar[] | []; total_count: number }>) => {
        if (!action.payload) return;
        state.car.list = action.payload.data;
        state.car.total_count = action.payload.total_count;
      },
    );
    builder.addCase(
      getContentCar.fulfilled,
      (state, action: PayloadAction<IContentTitleDescription>) => {
        state.content = action.payload;
      },
    );
    builder.addCase(getCar.fulfilled, (state, action: PayloadAction<ICurrentCar>) => {
      state.currentCar = action.payload;
    });
  },
  initialState,
  name: 'car',
  reducers: {
    clearCars: (state) => {
      state.currentCar = initialState.currentCar;
    },
    deleteImageFromGallery: (state, action: PayloadAction<ICurrentCarGallery[]>) => {
      if (!state.currentCar.gallery) return;
      state.currentCar.gallery = action.payload;
    },
    updateCapacity: (state, action: PayloadAction<number>) => {
      state.currentCar.capacity = action.payload;
    },
    updateCarName: (state, action: PayloadAction<string>) => {
      state.currentCar.car_name = action.payload;
    },
    updateCertifications: (state, action: PayloadAction<string>) => {
      state.currentCar.certifications = action.payload;
    },
    updateCheckedText: (state, action: PayloadAction<string>) => {
      state.currentCar.checked_text = action.payload;
    },
    updateContentCar: (state, action: PayloadAction<IContentTitleDescription>) => {
      state.content = action.payload;
    },
    updateDescription: (state, action: PayloadAction<string>) => {
      state.currentCar.description = action.payload;
    },
    updateEngine: (state, action: PayloadAction<number>) => {
      state.currentCar.engine = action.payload;
    },
    updateEngineType: (state, action: PayloadAction<string>) => {
      state.currentCar.engine_type = action.payload;
    },
    updateGallery: (state, action: PayloadAction<ICurrentCarGallery[]>) => {
      state.currentCar.gallery = state.currentCar.gallery
        ? [...state.currentCar.gallery, ...action.payload]
        : action.payload;
    },
    updateHeader: (state, action: PayloadAction<string>) => {
      state.currentCar.header = action.payload;
    },
    updateMileage: (state, action: PayloadAction<number>) => {
      state.currentCar.mileage = action.payload;
    },
    updatePrice: (state, action: PayloadAction<number>) => {
      state.currentCar.price_pln = action.payload;
    },
    updateTransmission: (state, action: PayloadAction<string>) => {
      state.currentCar.transmission = action.payload;
    },
    updateVerdictText: (state, action: PayloadAction<string>) => {
      state.currentCar.verdict_text = action.payload;
    },
    updateYear: (state, action: PayloadAction<number>) => {
      state.currentCar.year = action.payload;
    },
    uploadMoreCars: (state, action: PayloadAction<number>) => {
      state.page.limit = action.payload;
    },
  },
});

export const {
  uploadMoreCars,
  clearCars,
  updateCarName,
  updateDescription,
  updatePrice,
  updateYear,
  updateMileage,
  updateCapacity,
  updateEngine,
  updateEngineType,
  updateTransmission,
  updateCheckedText,
  updateVerdictText,
  updateCertifications,
  updateGallery,
  deleteImageFromGallery,
  updateContentCar,
  updateHeader,
} = carSlice.actions;
