import React, { FC, useEffect } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { useNavigate } from 'react-router-dom';

import { linksList } from '../../../constants';
import { clearBlog } from '../../../slices/blog.slice';
import { clearCars } from '../../../slices/car.slice';
import { selectLanguage } from '../../../slices/global-store.slice';
import { clearService } from '../../../slices/services.slice';
import { useAppDispatch } from '../../../store/store';

export const SidebarComponent: FC<IProps> = ({ isVisibleSidebar, closeSidebar }) => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  // const { selectedLanguage } = useAppSelector(({ globalStore }) => globalStore);

  useEffect(() => {
    const language = localStorage.getItem('language');
    if (!language) return;
    dispatch(selectLanguage(JSON.parse(language)));
  }, []);

  const handleRedirect = (route: string) => {
    dispatch(clearBlog());
    dispatch(clearCars());
    dispatch(clearService());
    navigation(route);
    closeSidebar();
  };

  // const handleSelect = async (value: ILanguage) => {
  //   await dispatch(selectLanguageAction(value));
  //   location.reload();
  // };

  return (
    <div className="card flex justify-content-center">
      <Sidebar visible={isVisibleSidebar} onHide={closeSidebar} className={'w-max'}>
        {/*<div className={'flex flex-col'}>*/}
        {/*  <Dropdown*/}
        {/*    value={selectedLanguage}*/}
        {/*    onChange={(e) => handleSelect(e.value)}*/}
        {/*    options={languages}*/}
        {/*    optionLabel="name"*/}
        {/*    placeholder="Выбрать язык"*/}
        {/*    className="w-full md:w-14rem"*/}
        {/*    defaultValue={selectedLanguage.code}*/}
        {/*  />*/}
        {/*</div>*/}
        <Accordion activeIndex={0} className={'mt-10'}>
          {linksList.map(({ label, items }, key) => (
            <AccordionTab header={label} key={key}>
              <div className={'flex flex-col'}>
                {items.map(({ label, url }, key) => (
                  <Button
                    label={label}
                    key={key}
                    onClick={() => handleRedirect(url)}
                    className={'mt-2'}
                  />
                ))}
              </div>
            </AccordionTab>
          ))}
        </Accordion>
      </Sidebar>
    </div>
  );
};

interface IProps {
  isVisibleSidebar: boolean;
  closeSidebar: () => void;
}
