import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputTextarea } from 'primereact/inputtextarea';

import { updateSection } from '../../actions/blog.action';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { updateContentBlog } from '../../slices/blog.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';

export const BlogsContentPage = () => {
  const dispatch = useAppDispatch();

  const { content } = useAppSelector(({ blog }) => blog);

  const handleUpdateBlogsListContent = (key: string, value: string) => {
    dispatch(updateContentBlog({ ...content, [key]: value }));
  };

  const handleSaveSectionBlog = () => {
    dispatch(updateSection({ section: content }));
  };

  return (
    <ContainerTemplate>
      <Card title="Блог контент" className={'border-2 border-yellow'}>
        <div>
          <label htmlFor="headline">Заголовок</label>
          <InputTextarea
            className={'w-full'}
            id="headline"
            value={content.title ?? ''}
            onChange={({ target }) => handleUpdateBlogsListContent('title', target.value)}
          />
        </div>
        <div className="mt-2">
          <label htmlFor="description">Описание</label>
          <InputTextarea
            className={'w-full'}
            id="description"
            value={content.description ?? ''}
            onChange={({ target }) => handleUpdateBlogsListContent('description', target.value)}
          />
        </div>
        <Button
          label="Сохранить"
          icon="pi pi-check"
          className={'mt-2'}
          onClick={handleSaveSectionBlog}
        />
      </Card>
    </ContainerTemplate>
  );
};
