import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getContentFormParts } from '../actions/form.action';
import { IDefaultStackSection, IStore } from '../store/types';

const initialState = {
  contact: {
    description: '',
    name: '',
    subtitle: '',
    title: '',
  },
  whatsChecked: {
    description: '',
    name: '',
    subtitle: '',
    title: '',
  },
} as IStore['formPage'];

export const formPageSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      getContentFormParts.fulfilled,
      (
        state,
        action: PayloadAction<{
          heading: IDefaultStackSection;
          what_checked: IDefaultStackSection;
        }>,
      ) => {
        state.contact = action.payload.heading;
        state.whatsChecked = action.payload.what_checked;
      },
    );
  },
  initialState,
  name: 'formPage',
  reducers: {
    updateContentFormPage: (state, action: PayloadAction<IDefaultStackSection>) => {
      state.contact = action.payload;
    },
    updateWhatsCheckedPage: (state, action: PayloadAction<IDefaultStackSection>) => {
      state.whatsChecked = action.payload;
    },
  },
});

export const { updateContentFormPage, updateWhatsCheckedPage } = formPageSlice.actions;
