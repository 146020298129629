import { FC } from 'react';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import { ICar } from '../../store/types';

export const CarItemComponent: FC<IProps> = ({ content, onClick, openWarningModal }) => {
  const header = content.image ? (
    <img alt="Card" className={'h-[380px] object-cover'} src={content.image} />
  ) : (
    <div className={'h-[380px]'} />
  );

  const getTitleContent = () => {
    return (
      <div className={'gap-2'}>
        {content.car_name}
        <div className={'mt-2'}>
          <p className={'text-white text-sm mb-2'}>Тип топлива: {content.engine_type}</p>
          <p className={'text-white text-sm mb-2'}>Год: {content.year}</p>
          <p className={'text-white text-sm'}>Пробег: {content.mileage}</p>
        </div>
      </div>
    );
  };

  const footer = (
    <div className="flex flex-wrap justify-content-end gap-2">
      <Button
        label="Редактировать"
        icon="pi pi-file-edit"
        onClick={() => onClick(content.id ? content.id : 0)}
        className={'w-full'}
      />
      <Button
        label="Удалить"
        severity="danger"
        icon={'pi pi-times'}
        onClick={() => openWarningModal(content.id ? content.id : 0)}
        className={'w-full'}
      />
    </div>
  );

  return (
    <div className="card flex justify-content-center justify-items-end">
      <Card
        title={getTitleContent}
        footer={footer}
        header={header}
        className="md:w-25rem flex flex-col justify-between"
      />
    </div>
  );
};

interface IProps {
  content: ICar;
  onClick: (id: number) => void;
  openWarningModal: (id: number) => void;
}
