import React from 'react';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { FileUploadUploadEvent } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';

import { updateSection } from '../../actions/main.action';
import { FileUploadComponent } from '../../components/car/file-upload.component';
import { ImageGalleriaComponent } from '../../components/car/image-galleria.component';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import {
  deleteBannerImageFromGallery,
  updateBanner,
  updateGalleryBanner,
} from '../../slices/main-content.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { IImageItem } from '../../store/types';

export const BannerPage = () => {
  const dispatch = useAppDispatch();

  const { banner } = useAppSelector(({ main }) => main.sections);
  const handleUpdateBannerContent = (key: string, value: string) => {
    dispatch(updateBanner({ ...banner, [key]: value }));
  };

  const handleSaveBanner = () => {
    console.log('bannersection', banner);
    dispatch(
      updateSection({
        section: banner,
      }),
    );
  };

  const onUpload = (e: FileUploadUploadEvent) => {
    const imageData: IImageItem[] = JSON.parse(e.xhr.response);
    dispatch(updateGalleryBanner(imageData));
  };

  const handleDeleteImage = (v: string) => {
    if (!banner.gallery?.length) return;
    const data = banner.gallery.filter((item) => item?.image_path !== v);
    dispatch(deleteBannerImageFromGallery(data));
  };

  return (
    <ContainerTemplate>
      {banner.gallery?.length ? (
        <ImageGalleriaComponent gallery={banner.gallery} handleDeleteImage={handleDeleteImage} />
      ) : null}

      <FileUploadComponent url={'pages/upload_gallery'} onUpload={onUpload} />

      <Card title="Главный баннер" className={'border-2 border-yellow'}>
        <div>
          <label htmlFor="headline">Заголовок</label>
          <InputTextarea
            className={'w-full'}
            id="headline"
            value={banner.title ?? ''}
            onChange={({ target }) => handleUpdateBannerContent('title', target.value)}
          />
        </div>
        <div className="mt-2">
          <label htmlFor="description">Описание</label>
          <InputTextarea
            className={'w-full'}
            id="description"
            value={banner.description ?? ''}
            onChange={({ target }) => handleUpdateBannerContent('description', target.value)}
          />
        </div>
        <Button
          label="Сохранить"
          icon="pi pi-check"
          className={'mt-2'}
          onClick={handleSaveBanner}
        />
      </Card>
    </ContainerTemplate>
  );
};
