import React, { FC } from 'react';

import { Galleria, GalleriaResponsiveOptions } from 'primereact/galleria';

import { ICurrentCarGallery } from '../../store/types';

const responsiveOptions: GalleriaResponsiveOptions[] = [
  {
    breakpoint: '991px',
    numVisible: 4,
  },
  {
    breakpoint: '767px',
    numVisible: 3,
  },
  {
    breakpoint: '575px',
    numVisible: 1,
  },
];

export const ImageGalleriaComponent: FC<IProps> = ({ gallery, handleDeleteImage }) => {
  const itemTemplate = (item: ICurrentCarGallery) => {
    return (
      <div className={'relative'}>
        {item ? (
          <img src={item.image_url} alt={item.image_url} style={{ width: '100%' }} />
        ) : (
          <img
            src={gallery ? gallery[0].image_url : ''}
            alt={gallery ? gallery[0].image_url : ''}
            style={{ width: '100%' }}
          />
        )}
        <i
          className="pi pi-times absolute top-5 right-5 cursor-pointer"
          onClick={() => handleDeleteImage(item.image_path)}
          style={{ color: 'red', fontSize: '1.5rem' }}
        ></i>
      </div>
    );
  };

  const thumbnailTemplate = (item: ICurrentCarGallery) => {
    return <img src={item.image_url} alt={item.image_url} style={{ maxWidth: '150px' }} />;
  };

  return (
    <div className="card flex items-center justify-center mb-10">
      <Galleria
        value={gallery as any[]}
        responsiveOptions={responsiveOptions}
        numVisible={5}
        style={{ maxWidth: '640px' }}
        item={itemTemplate}
        thumbnail={thumbnailTemplate}
      />
    </div>
  );
};

interface IProps {
  gallery: ICurrentCarGallery[];
  handleDeleteImage: (v: string) => void;
}
