import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputTextarea } from 'primereact/inputtextarea';

import { updateFormContentAction } from '../../actions/form.action';
import { ContainerTemplate } from '../../components/core-components/templates/container.template';
import { updateContentFormPage } from '../../slices/form.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';

export const FormPage = () => {
  const dispatch = useAppDispatch();

  const { contact } = useAppSelector((store) => store.formPage);

  const handleUpdateContentTitle = (value: string) => {
    dispatch(updateContentFormPage({ ...contact, title: value }));
  };

  const handleUpdateContentDescription = (value: string) => {
    dispatch(updateContentFormPage({ ...contact, description: value }));
  };

  const handleSaveSection = () => {
    dispatch(updateFormContentAction({ section: contact }));
  };

  return (
    <ContainerTemplate>
      <Card
        title={`Страница с формой. Заголовок и описание`}
        className={'border-2 border-yellow mb-10'}
      >
        <div>
          <label htmlFor="headline">Заголовок</label>
          <InputTextarea
            className={'w-full'}
            id="headline"
            value={contact.title ?? ''}
            onChange={({ target }) => handleUpdateContentTitle(target.value)}
          />
        </div>
        <div>
          <label htmlFor="description">Описание</label>
          <InputTextarea
            className={'w-full'}
            id="description"
            value={contact.description ?? ''}
            onChange={({ target }) => handleUpdateContentDescription(target.value)}
          />
        </div>
        <div className={'flex justify-end'}>
          <Button
            label="Сохранить"
            icon="pi pi-check"
            className={'my-2'}
            onClick={handleSaveSection}
          />
        </div>
      </Card>
    </ContainerTemplate>
  );
};
