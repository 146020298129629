import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../api/api';
import { AppDispatch } from '../store/store';
import { ICarParts } from '../store/types';

import { handlePayloadInfoAction } from './globall.action';

export const getAllCarParts = createAsyncThunk<{ data: ICarParts[] }, void>(
  'cars-part/get',
  async () => {
    const { data } = await api.carParts.getAllCarsPart();
    if (!data) throw 'error';

    return data;
  },
);

// export const getContentCarParts = createAsyncThunk<IContentTitleDescription, void>(
//   'content-car-parts/get',
//   async () => {
//     const { data } = await api.carParts.getContentCarParts();
//     if (!data) throw 'error';
//     return data.data.heading;
//   },
// );

export const updateCarPartsAction = createAsyncThunk<void, ICarParts, { dispatch: AppDispatch }>(
  'cars-part/update',
  async (data, { dispatch }) => {
    const { status } = await api.carParts.updateCarPart(data);

    dispatch(handlePayloadInfoAction({ statusCode: status }));

    await dispatch(getAllCarParts());
  },
);
